import './campaignsTable.scss';

import { fromNullable } from 'fp-ts/lib/Option';
import { produce } from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import first from 'lodash/first';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import last from 'lodash/last';
import merge from 'lodash/merge';
import noop from 'lodash/noop';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import {
  BreadCrumbsProps,
  ButtonState,
  ContextSetterV3,
  DualModes,
  Layout,
  LevelsController,
  Loader,
  SelectMenuVariant,
  SelectOptionProps,
  SelectV2OptionProps,
  SelectV2State,
  Spinner,
  Type,
} from '@teikametrics/tm-design-system';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { TargetingType } from 'lib/types/KeywordAction';
import { AOConnectionBanner, AppHeaderTitleProps } from '../..';
import EmptyState from '../../../../components/EmptyState';
import {
  useEmptyStateData,
  useMerchantsSyncing,
} from '../../../../components/EmptyState/hooks';
import { PageType } from '../../../../components/EmptyState/types';
import { ViewSkuAndKeywordSlideOutV2 } from '../../../../components/ViewSkuAndKeywordSlideoutV2';
import { useBidConstraintsContext } from '../../../../containers/bidConstraintsProvider/bidConstraintsProvider';
import { useDataSyncInfoContext } from '../../../../containers/dataSyncInfoProvider/dataSyncInfoProvider';
import {
  MerchantCountriesContext,
  MerchantCountriesContextState,
} from '../../../../containers/merchantCountriesProvider/merchantCountriesProvider';
import {
  NotificationContext,
  NotificationContextState,
} from '../../../../containers/notificationProvider';
import {
  OptimizelyContext,
  OptimizelyContextState,
} from '../../../../containers/optimizelyProvider/optimizelyProvider';
import {
  PageHeaderContext,
  PageHeaderContextState,
} from '../../../../containers/pageHeaderProvider';
import {
  SaveChangesFlagContext,
  SaveChangesFlagContextState,
} from '../../../../containers/saveChangesFlagProvider';
import { SubscriptionContext } from '../../../../containers/subscriptionProvider';
import {
  tableActions,
  tableSelectors,
  tableThunks,
} from '../../../../containers/table/ducks';
import {
  TableChange,
  WithTable,
} from '../../../../containers/table/ducks/types';
import {
  getCurrentAccountFromContext,
  isAIPlanEnabled,
} from '../../../../containers/userProvider/selectors';
import {
  UserContext,
  UserContextState,
} from '../../../../containers/userProvider/userProvider';
import { AOApiClient } from '../../../../lib/clients/AOApiClient';
import { createCampaignFlexibilityApiClient } from '../../../../lib/clients/CampaignFlexibilityClient';
import { FAMApiClient } from '../../../../lib/clients/FAMApiClient';
import { PaginatedResponseExtraData } from '../../../../lib/clients/types';
import { getSalesChannelsWithMerchantCountriesInfo } from '../../../../lib/clients/utils';
import {
  AdGroupDataRequest,
  AdGroupDetails,
  AdLevel,
  AdLevelApiEndpoint,
  AdType,
  AddedBy,
  CampaignDataRequest,
  CampaignDetails,
  CampaignGroupAssignRequest,
  CampaignTargetingType,
  DataAvailabilityInfo,
  DateRange,
  EditProductAdRequest,
  EditProfileRequest,
  EditTargetsRequest,
  EntityType,
  FLYWHEEL_CHANNEL_TO_MERCHANT_PICKER_TYPE_MAPPER,
  FlywheelSalesChannel,
  Groups,
  HeroMetricsAggregationPeriodType,
  HeroMetricsData,
  MerchantCountryCode,
  MerchantCountryId,
  MerchantPickerType,
  PerformanceMetricsRequest,
  Portfolio,
  ProductAdDetails,
  ProductAdsDataRequest,
  ProfileApiDataDetails,
  ProfileApiDataRequest,
  REQUEST_DATE_FORMAT,
  SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2,
  SalesChannelId,
  SbAdsDataRequest,
  SbAdsDetails,
  TargetsDataRequest,
  TargetsDetails,
  UpdateAdgroupChannelSettingsRequest,
  UpdateCampaignChannelSettingsRequest,
  UpdateFlywheelSettingsRequest,
} from '../../../../lib/types/AOSharedTypes';
import { AvailabilityStatus } from '../../../../lib/types/CampaignFlexibilitySharedTypes';
import {
  AllSalesChannel,
  ConnectionStatus,
  FAMConnectionStatus,
  MerchantCountry,
  MerchantType,
  MerchantsBySalesChannel,
  SalesChannel,
  SalesChannelData,
} from '../../../../lib/types/Fam';
import { Filter } from '../../../../lib/types/Filter';
import I18nKey from '../../../../lib/types/I18nKey';
import { OptimizelyFlags } from '../../../../lib/types/OptimizelyFlags';
import {
  AsyncRequest,
  AsyncRequestCompleted,
  AsyncRequestFailed,
  AsyncRequestLoading,
  AsyncRequestNotStarted,
  asyncRequestIsComplete,
  asyncRequestIsFailed,
} from '../../../../lib/utilities/asyncRequest';
import {
  CurrencyCode,
  getCurrencyCodeFromMerchantCountryCode,
} from '../../../../lib/utilities/currency';
import { equalToFilter, inFilter } from '../../../../lib/utilities/filter';
import { talkToUs } from '../../../../lib/utilities/intercom';
import {
  getCurrentPageFromSessionStorage,
  storeCurrentPageToSessionStorage,
} from '../../../../lib/utilities/storageUtils';
import { SelectedTrendsProduct } from '../../../products/containers/skuCatalog';
import { PerformanceSlideover } from '../../../products/containers/skuCatalog/performanceSlideover';
import { PerformanceSlideoverContextProvider } from '../../../products/containers/skuCatalog/performanceSlideover/performanceSlideoverContextProvider';
import { HeroMetricsV2 } from '../../components/HeroMetrics';
import { ViewTrends } from '../../components/ViewTrends';
import { ViewTrendsContext } from '../../components/ViewTrends/ViewTrendsProvider';
import { MerchantContext, MerchantContextState } from '../../merchantsProvider';
import { AdOptimizationState } from '../../redux/types';
import {
  isIdentifiedAdlevelOneOfTargets,
  transFormAdItemsFilters,
} from '../../utils';
import { getMerchantCountryId } from './adgroupTableColumns/adgroupName';
import { CreateChatGPTKeywordModal } from './createKeywords/chatGPT/createChatGPTKeywordsModal';
import { CreateChatGPTKeywordsProvider } from './createKeywords/chatGPT/createChatGPTKeywordsProvider';
import actions from './ducks/actions';
import {
  ADS_MANAGER_ADGROUP_TABLE_ID,
  ADS_MANAGER_AD_ITEMS_TABLE_ID,
  ADS_MANAGER_CAMPAIGNS_TABLE_ID,
  ADS_MANAGER_PROFILES_TABLE_ID,
  ADS_MANAGER_SV_ADS_TABLE_ID,
  ADS_MANAGER_TARGETS_TABLE_ID,
} from './ducks/types';
import { EnableAIBanner } from './enableAIBanner';
import { TableProductAds } from './tableAdItems';
import { TableAdGroup } from './tableAdgroup';
import { TableCampaigns } from './tableCampagins';
import { TableProfiles } from './tableProfiles';
import { TableSVAds } from './tableSVAds';
import { TableSbAds } from './tableSbAds';
import { TableTargets } from './tableTargets';
import {
  ADLEVEL_DETAILS_FILTER_FIELD,
  ADLEVEL_TO_ADLEVEL_API_MAPPER,
  ADS_MANAGER_AD_LEVEL_PAGES,
  ADS_MANAGER_AD_LEVEL_PAGES_URI,
  ADS_MANAGER_DETAILS_PAGE_AD_LEVEL_MAPPER,
  ADS_MANAGER_EMBEDDED_ROUTES,
  ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER,
  AD_LEVEL,
  AD_LEVEL_EXTERNAL_ID_MAPPER,
  AD_LEVEL_TO_ADS_MANAGER_PAGE_MAPPER,
  AD_TYPE_DEFAULT_VALUE,
  AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER,
  AdgroupDataApiColumnGroupIdentifier,
  ApiColumnsWithAdLevel,
  CampaignDataApiColumnGroupIdentifier,
  CampaignTypeQuickFilterKey,
  ChatGPTEmbeddedURLs,
  Checkbox,
  DataInspection,
  DataInspectionAdLevelConfigProps,
  DataInspectorColumnManagerConfigProps,
  END_DATE,
  ENTITY_TYPE_FOR_TARGET_MAPPER,
  MERCHANT_COUNTRY_IDS,
  MILLISECONDS_TO_DAYS,
  NO_GROUP_VALUE,
  ProductAdsApiColumnGroupIdentifier,
  ProfileDataApiColumnGroupIdentifier,
  SALES_CHANNEL_ID,
  SP_CAMPAIGN_NEGATIVE_KEYWORD,
  SP_NEGATIVE_KEYWORD,
  START_DATE,
  SbAdsApiColumnGroupIdentifier,
  TARGETING_TYPE,
  TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
  TARGET_TYPE_API_RESPONSE_TO_AD_LEVEL_MAPPER,
  TARGET_TYPE_API_TO_AD_LEVEL_MAPPER,
  TabCheckbox,
  TableCellChangeProductAd,
  TableCellChangeProfile,
  TableCellChangeTarget,
  TargetsDataApiColumnGroupIdentifier,
} from './types';
import { UpgradePlanWrapper } from './upgradePlanWrapper';
import {
  ADGROUPS_API_COLUMN_NAME,
  ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  AD_ITEMS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  AD_LEVEL_API_COLUMNS_TO_EXCLUDE,
  ALL_AD_LEVELS_FILTER_FILED_MAPPER,
  CAMPAIGNS_API_COLUMN_NAME,
  CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER,
  MANDATORY_AD_ITEMS_COLUMNS_AD_ITEM_DETAILS,
  MANDATORY_CAMPAIGN_COLUMNS_CAMPAIGN_DETAILS,
  MANDATORY_CAMPAIGN_COLUMNS_CHANNEL_SETTINGS,
  MANDATORY_CHANNEL_SETTINGS_TARGETS_COLUMNS,
  MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS,
  PRODUCT_ADS_API_COLUMN_NAME,
  PROFILES_API_COLUMN_NAME,
  PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  TARGETS_API_COLUMN_NAME,
  TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER,
  checkIfAdLevelPathsAreOfSameLevel,
  checkIfUrlPathsAreOfSameAdLevel,
  findSalesChannelByChannelId,
  generateMerchantPickerOptions,
  getAdLevelColumnManagerCheckboxData,
  getAdLevelInfo,
  getAiHintStatusIfAdgroupOrCampaginSBType,
  getAllAdLevelTabCheckboxesMetaData,
  getAllApiColumns,
  getColumnManagerOptions,
  getDatePresentRangeForTracking,
  getDropDownMetaDataForDataInspection,
  getDropdownValuesFromPathname,
  getFlywheelChannelFromSalesChannelId,
  getFlywheelSettingsColumn,
  getMerchantAsArray,
  getMerchantCountriesForSalesChannel,
  getMerchantCountryNameForCSVDownload,
  getNewTabCheckboxValues,
  getPermittedDateRange,
  getRequestAdvertisingType,
  getSelectedMerchantCountryId,
  getTableIdByAdLevel,
  getTargetingExpressionAndTargetLabelFilters,
  getTransformedAddedByFilter,
  getTransformedFilters,
  isAIEnabledForMerchants,
  isAdLevelAvailableInMetadata,
  isAdTypeAvailableInMetadata,
  mapDataInspectorColumnToApiColumn,
  previousDayDate,
  sevenDayBackDate,
  shouldFetchSpecificTargetTypes,
  shouldShowSmartCampaigns,
  sortGroupsByName,
  sortPortfoliosByName,
  sortSalesChannelsByName,
} from './utils';
import {
  getEditProductAdRequest,
  getEditProfileRequest,
  getEditTargetRequest,
  getUpdateAdgroupsChannelSettingsRequest,
  getUpdateCampaignChannelSettingsRequest,
  getUpdateFlywheelSettingsRequest,
  getUpdateFlywheelSettingsRequestForCampaigns,
  getUpdateGroupsPayload,
  isCampaignDailyBudgetColumnMandatoryForAdGroups,
} from './utils/apiRequestUtils';
import {
  disableFwSettingsColumnsIfBasicPlan,
  enableFwSettingsColumns,
  filterTargetLabelandAddedByColumn,
  updateSmartAcosColumnsConfig,
} from './utils/columnManagerUtils';
import {
  getAdLevelFromLocalStorage,
  getAdTypeFromLocalStorage,
  getBreadCrumbDetailsFromLocalStorage,
  getDetailsPageSelectedAdLevelFromSessionStorage,
  getMerchantFromLocalStorage,
  getSalesChannelFromLocalStorage,
  getStartDateEndDateFromLocalStorage,
  removeAllDetailsPageFilters,
  removeBreadCrumbDetailsInLocalStorage,
  removeFiltersInLocalStorage,
  removeStoreDetailsTargetAdLevelSessionStorage,
  storeAdLevelToLocalStorage,
  storeAdTypeToLocalStorage,
  storeBreadCrumbDetailsInLocalStorage,
  storeDetailsPageSelectedAdLevelToSessionStorage,
  storeDetailsTargetAdLevelToLocalStorage,
  storeFiltersInLocalStorage,
  storeMerchantToLocalStorage,
  storeSalesChannelToLocalStorage,
  storeStartDateEndDateInLocalStorage,
  storeTabCheckboxColumnSelectionInfoInLocalStorage,
} from './utils/storageUtils';

interface AdsManagerProps {
  readonly aoApiClient: AOApiClient;
  readonly famApiClient: FAMApiClient;
  readonly setAppHeaderTitle: Dispatch<SetStateAction<AppHeaderTitleProps>>;
  readonly appHeaderTitle: AppHeaderTitleProps;
  readonly displayConnectionsBanner: AOConnectionBanner;
  readonly shouldDisplayServerErrorMessageInBanner?: boolean;
  readonly setAdLevelForBreadcrumb: (adlevel: AdLevel) => void;
  readonly flywheelSalesChannels: AllSalesChannel[];
  readonly breadCrumbs?: React.ReactElement<BreadCrumbsProps>;
  readonly productConnectionStatus?: ConnectionStatus;
  readonly pageTitle?: React.ReactNode;
  readonly merchantsBySalesChannel: MerchantsBySalesChannel[];
  readonly updateMerchantsBySalesChannelOnAIPlanEnable: (
    updateAllMerchants: boolean,
    salesChannelName?: FlywheelSalesChannel,
    merchants?: SalesChannelData[]
  ) => void;
  readonly defaultTableMode: DualModes;
  readonly currentDateRange?: DateRange;
  readonly runningTests?: boolean;
}

const AD_GROUP_DETAILS_COLUMN = 'adGroupDetails';
const AD_ITEM_DETAILS_COLUMN = 'adItemDetails';
const PORFILE_DETAILS_COLUMN = 'profileDetails';
const TARGET_DETAILS_COLUMN = 'targetDetails';
const CAMPAIGN_HEADER_TITLE_KEY = 'campaignHeader';
const ADGROUP_HEADER_TITLE_KEY = 'adGroupHeader';

const DetailsPageToColumnMapper: Record<string, string> = {
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.Campaigns]: 'campaignName',
  [ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups]: 'adGroupName',
};

export const getMerchantCountryIdsForInitialLoad = (
  merchantCountries: MerchantCountry[],
  salesChannel: FlywheelSalesChannel
): MerchantCountryId => {
  const merchantIds = sortBy(merchantCountries, [
    ({ merchantCountryId, merchantName }) =>
      isNil(merchantCountryId) ? merchantCountryId : merchantName,
  ]).map(({ merchantCountryId }) => merchantCountryId);

  if (
    FLYWHEEL_CHANNEL_TO_MERCHANT_PICKER_TYPE_MAPPER[salesChannel] ===
    MerchantPickerType.Single
  ) {
    return merchantIds[0];
  }

  return merchantIds.length > 1 ? [merchantIds[0]] : merchantIds;
};

const getTargetingTypeParam = (targetingTypeParam: string | null) =>
  targetingTypeParam
    ? (targetingTypeParam as CampaignTargetingType)
    : undefined;

const getDropdownState = (adsManagerDetailsPageFlag: boolean) =>
  adsManagerDetailsPageFlag ? SelectV2State.Disabled : SelectV2State.Default;

// eslint-disable-next-line max-statements
const AdsManager: React.FC<AdsManagerProps> = ({
  aoApiClient,
  famApiClient,
  setAppHeaderTitle,
  appHeaderTitle,
  displayConnectionsBanner,
  setAdLevelForBreadcrumb,
  flywheelSalesChannels,
  breadCrumbs,
  productConnectionStatus,
  pageTitle,
  merchantsBySalesChannel,
  updateMerchantsBySalesChannelOnAIPlanEnable,
  defaultTableMode,
  runningTests = false,
  currentDateRange,
}) => {
  const location = useLocation();
  let { prevPath } = (location.state && (location.state as any)) || {};
  let dateRangeRef = useRef<boolean>(false);

  const nextLoadCurrentHeroMetricsRef = useRef(noop);
  const isCurrentHeroMetricsLoadingRef = useRef<boolean>(false);

  const nextPreviousHeroMetircsRef = useRef(noop);
  const isPreviousHeroMetricsLoadingRef = useRef<boolean>(false);

  const { updateSubscriptionInformation } = useContext(SubscriptionContext);
  const viewTrendsContext = useContext(ViewTrendsContext);
  const { aoDataSyncInfo } = useDataSyncInfoContext();

  const urlPathNameList = location.pathname.split('/');
  const {
    identifiedAdLevel,
    adsManagerDetailsPageFlag,
    rootAdLevelId,
    adsManagerDetailsPageName,
    currentAdLevelId,
  } = getAdLevelInfo(urlPathNameList);

  const targetingTypeParam = new URLSearchParams(
    decodeURIComponent(location.search)
  ).get(TARGETING_TYPE);

  const intl = useIntl();
  const dispatch = useDispatch();

  const pathName = location.pathname.split('/');
  let adLevelId = pathName.includes('create-chatgpt')
    ? pathName[pathName.length - 2]
    : pathName[pathName.length - 1];

  adLevelId = adLevelId ? adLevelId.split('&')[0] : adLevelId;

  const userContext = useContext<UserContextState>(UserContext);
  const toasts = useContext<NotificationContextState>(NotificationContext);
  const accountId = getCurrentAccountFromContext(userContext)!.id;
  const userId = userContext.userInfo.userDetails?.id || '';
  const saveChangesFlagContext = useContext<SaveChangesFlagContextState>(
    SaveChangesFlagContext
  );
  const idToken = userContext.userInfo.idToken!;
  const campaignFlexibilityClient = createCampaignFlexibilityApiClient(idToken);

  const isChatGPTUrl = useMemo(
    () => location.pathname.includes('create-chatgpt'),
    [location.pathname]
  );

  const [disCheckavailability, setDisCheckavailability] =
    useState<boolean>(false);
  const [subscribedSalesChannels, setSubscribedSalesChannels] = useState<
    SalesChannel[]
  >([]);

  const [selectedSalesChannelId, setSelectedSalesChannelId] =
    useState<string>('');

  const [firstPageLoad, setFirstPageLoad] = useState<boolean>(true);

  const [
    merchantCountriesForSelectedSalesChannel,
    setMerchantCountriesForSelectedSalesChannel,
  ] = useState<MerchantCountry[]>([]);

  const [selectedMerchantCountries, setSelectedMerchantCountries] = useState<
    Array<number | string> | string
  >([]);

  const [dataInspectionRequest, setDataInspectionRequest] = useState<
    AsyncRequest<DataInspection[]>
  >(AsyncRequestNotStarted());

  const [isSaveChangesLoading, setIsSaveChangesLoading] =
    useState<boolean>(false);

  const currentFlywheelSalesChannel: FlywheelSalesChannel =
    (findSalesChannelByChannelId(
      subscribedSalesChannels,
      selectedSalesChannelId
    )?.name as FlywheelSalesChannel) ?? FlywheelSalesChannel.Walmart;

  const currentHerometricsPerformanceFieldsV2 =
    SALES_CHANNEL_TO_PERFORMANCE_FIELD_MAPPER_V2[currentFlywheelSalesChannel];

  const filteredMerchantCountryInfo: MerchantCountry[] =
    merchantCountriesForSelectedSalesChannel.filter(
      (merchantCountry) =>
        merchantCountry.merchantCountryId ===
        (isArray(selectedMerchantCountries)
          ? first(selectedMerchantCountries)
          : selectedMerchantCountries)
    );

  const selectedMerchantCountryInfo = first(filteredMerchantCountryInfo);

  const merchantCountryNameForCSVDownload =
    getMerchantCountryNameForCSVDownload(
      selectedMerchantCountries,
      selectedMerchantCountryInfo
    );

  const selectedMerchantType = selectedMerchantCountryInfo?.merchantType;

  const selectedMerchantCountryCode: MerchantCountryCode =
    (selectedMerchantCountryInfo?.country as MerchantCountryCode) ||
    MerchantCountryCode.US;

  const targetTableData = useSelector<AdOptimizationState, TargetsDetails[]>(
    ({ tableState }) =>
      tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<TargetsDetails>()(
          tableState,
          ADS_MANAGER_TARGETS_TABLE_ID
        )
      )
  );

  const campaignTableData = useSelector<AdOptimizationState, CampaignDetails[]>(
    ({ tableState }) =>
      tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<CampaignDetails>()(
          tableState,
          ADS_MANAGER_CAMPAIGNS_TABLE_ID
        )
      )
  );
  const profileTableData = useSelector<
    AdOptimizationState,
    ProfileApiDataDetails[]
  >(({ tableState }) => {
    return tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<ProfileApiDataDetails>()(
        tableState,
        ADS_MANAGER_PROFILES_TABLE_ID
      )
    );
  });

  const breadCrumbDetailsFromLocalStorage =
    rootAdLevelId &&
    (getBreadCrumbDetailsFromLocalStorage(
      userId,
      accountId,
      rootAdLevelId
    ) as AppHeaderTitleProps);

  useEffect(() => {
    rootAdLevelId &&
      storeBreadCrumbDetailsInLocalStorage(
        userId,
        accountId,
        rootAdLevelId,
        appHeaderTitle
      );
  }, [appHeaderTitle]);

  !adsManagerDetailsPageFlag &&
    removeStoreDetailsTargetAdLevelSessionStorage(userId, accountId);

  const appHeaderTitleName =
    adsManagerDetailsPageName === ADS_MANAGER_AD_LEVEL_PAGES_URI.AdGroups
      ? ADGROUP_HEADER_TITLE_KEY
      : CAMPAIGN_HEADER_TITLE_KEY;

  const detailsPageColumnName: string =
    DetailsPageToColumnMapper[adsManagerDetailsPageName];

  useEffect(() => {
    if (
      targetTableData?.length &&
      adsManagerDetailsPageFlag &&
      (identifiedAdLevel === AdLevel.AdGroups ||
        identifiedAdLevel === AdLevel.Campaigns) &&
      !isEmpty(
        targetTableData[0][TARGET_DETAILS_COLUMN][detailsPageColumnName]
      ) &&
      !isEqual(
        appHeaderTitle[appHeaderTitleName],
        targetTableData[0][TARGET_DETAILS_COLUMN][detailsPageColumnName]
      )
    ) {
      setAppHeaderTitle((prevState: AppHeaderTitleProps) => ({
        ...prevState,
        [appHeaderTitleName]: targetTableData[0][TARGET_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string,
      }));
    }
  }, [targetTableData]);

  const adGrouptableData = useSelector<AdOptimizationState, AdGroupDetails[]>(
    ({ tableState }) =>
      tableSelectors.getVisibleData(
        tableSelectors.getTableSelector<AdGroupDetails>()(
          tableState,
          ADS_MANAGER_ADGROUP_TABLE_ID
        )
      )
  );

  useEffect(() => {
    if (
      adGrouptableData?.length &&
      adsManagerDetailsPageFlag &&
      identifiedAdLevel === AdLevel.Campaigns &&
      !isEmpty(
        adGrouptableData[0][AD_GROUP_DETAILS_COLUMN][detailsPageColumnName]
      ) &&
      !isEqual(
        appHeaderTitle[appHeaderTitleName],
        adGrouptableData[0][AD_GROUP_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string
      )
    ) {
      setAppHeaderTitle((prevState: AppHeaderTitleProps) => ({
        ...prevState,
        [appHeaderTitleName]: adGrouptableData[0][AD_GROUP_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string,
      }));
    }
  }, [adGrouptableData]);

  useEffect(() => {
    if (
      profileTableData?.length &&
      adsManagerDetailsPageFlag &&
      identifiedAdLevel === AdLevel.AdGroups &&
      !isEmpty(profileTableData[0][PORFILE_DETAILS_COLUMN]) &&
      !isEqual(
        appHeaderTitle[appHeaderTitleName],
        profileTableData[0][PORFILE_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string
      )
    ) {
      setAppHeaderTitle((prevState: AppHeaderTitleProps) => ({
        ...prevState,
        [appHeaderTitleName]: profileTableData[0][PORFILE_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string,
      }));
    }
  }, [profileTableData]);

  const productAdTableData = useSelector<
    AdOptimizationState,
    ProductAdDetails[]
  >(({ tableState }) =>
    tableSelectors.getVisibleData(
      tableSelectors.getTableSelector<ProductAdDetails>()(
        tableState,
        ADS_MANAGER_AD_ITEMS_TABLE_ID
      )
    )
  );

  useEffect(() => {
    if (
      productAdTableData?.length &&
      adsManagerDetailsPageFlag &&
      (identifiedAdLevel === AdLevel.AdGroups ||
        identifiedAdLevel === AdLevel.Campaigns) &&
      !isEmpty(
        productAdTableData[0][AD_ITEM_DETAILS_COLUMN][detailsPageColumnName]
      ) &&
      !isEqual(
        appHeaderTitle[appHeaderTitleName],

        productAdTableData[0][AD_ITEM_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string
      )
    ) {
      setAppHeaderTitle((prevState: AppHeaderTitleProps) => ({
        ...prevState,
        [appHeaderTitleName]: productAdTableData[0][AD_ITEM_DETAILS_COLUMN][
          detailsPageColumnName
        ] as string,
      }));
    }
  }, [productAdTableData]);

  const [adTypeDropDownValue, setAdTypeDropDownValue] = useState<AdType>();

  const [adLevelDropDownSelectedValues, setAdLevelDropDownSelectedValues] =
    useState<AdLevel | ''>('');

  const targetTypeRequestParameter =
    (adLevelDropDownSelectedValues &&
      ENTITY_TYPE_FOR_TARGET_MAPPER[adLevelDropDownSelectedValues]) ||
    undefined;

  const [tabCheckboxMetadata, setTabCheckboxMetadata] = useState<TabCheckbox>(
    {}
  );

  useEffect(() => {
    const params = new URLSearchParams(decodeURIComponent(location.search));
    if (
      adsManagerDetailsPageFlag &&
      params.get(AD_LEVEL) !== adLevelDropDownSelectedValues
    ) {
      params.delete(AD_LEVEL);
      params.append(AD_LEVEL, adLevelDropDownSelectedValues);
      navigate(`${location.pathname}?${params.toString()}`, {
        replace: true,
        state: { prevPath },
      });
    }
  }, [identifiedAdLevel, adLevelDropDownSelectedValues]);

  const [apiColumnsWithAdLevel, setApiColumnsWithAdLevel] =
    useState<ApiColumnsWithAdLevel>({
      columns: [],
      adLevel: undefined,
    });

  const isAiEnabled = isAIPlanEnabled(userContext);

  const selectedFlywheelChannel = getFlywheelChannelFromSalesChannelId(
    selectedSalesChannelId,
    subscribedSalesChannels
  );

  const isAIEnabledForOneOfTheSelectedMerchants = useMemo(
    () =>
      isAIEnabledForMerchants(
        selectedMerchantCountries,
        selectedFlywheelChannel,
        merchantsBySalesChannel
      ),
    [selectedMerchantCountries, adTypeDropDownValue]
  );

  const optimizelyContext =
    useContext<OptimizelyContextState>(OptimizelyContext);

  const [tabsCheckboxesForAllAdLevels, setTabsCheckboxesForAllAdLevels] =
    useState<DataInspectorColumnManagerConfigProps>(() => {
      const config = getAllAdLevelTabCheckboxesMetaData(
        userId,
        accountId,
        isAiEnabled
      );
      const smartAcosConfig = updateSmartAcosColumnsConfig(
        config,
        shouldShowSmartCampaigns(optimizelyContext)
      );
      const fwSettingsConfig = disableFwSettingsColumnsIfBasicPlan(
        isAIPlanEnabled(userContext),
        smartAcosConfig
      );
      return filterTargetLabelandAddedByColumn(isAiEnabled, fwSettingsConfig);
    });

  const [exportButtonState, setExportButtonState] = useState<ButtonState>(
    ButtonState.Enabled
  );

  const [heroMetricsLoadAttempts, setHeroMetricsLoadAttempts] =
    useState<number>(0);

  const [heroMetricsCurrentMetricRequest, setHeroMetricsCurrentMetricRequest] =
    useState<AsyncRequest<HeroMetricsData>>(AsyncRequestNotStarted());

  const [
    heroMetricsPreviousMetricRequest,
    setHeroMetricsPreviousMetricRequest,
  ] = useState<AsyncRequest<HeroMetricsData>>(AsyncRequestNotStarted());

  const merchantContext = useContext<MerchantContextState>(MerchantContext);

  const initialDataPickerState: DateRange =
    runningTests && currentDateRange
      ? currentDateRange
      : {
          initialStartDate: sevenDayBackDate,
          initialEndDate: previousDayDate,
          minDate: sevenDayBackDate,
          maxDate: previousDayDate,
        };

  const [selectedDateRange, setSelectedDateRange] = useState<DateRange>(
    initialDataPickerState
  );
  const [dateRangeDataTestId, setDateRangeDataTestId] = useState<string>('');
  const [dateRangeDataTestIdForStartDate, setDateRangeDataTestIdForStartDate] =
    useState<string>('');

  const [tableMode, setTableMode] = useState<DualModes>(defaultTableMode);

  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const [aoGroups, setAoGroups] = useState<Groups[]>([]);

  const [showTrendsSlideover, setShowTrendsSlideover] =
    useState<boolean>(false);
  const [selectedTrendsProduct, setSelectedTrendsProduct] =
    useState<SelectedTrendsProduct>();

  const onViewTrendsSlideoverClick = (product: SelectedTrendsProduct) => {
    setSelectedTrendsProduct(product);
    setShowTrendsSlideover(true);
    if (viewTrendsContext.closeViewTrends) {
      viewTrendsContext.closeViewTrends();
    }
  };

  const onViewTrendsSlideoverClose = () => {
    setShowTrendsSlideover(false);
    setSelectedTrendsProduct(undefined);
  };

  useEffect(() => {
    dispatch(actions.setSelectedSalesChannel(selectedSalesChannelId));
  }, [selectedSalesChannelId, location.pathname]);

  useEffect(() => {
    if (selectedMerchantCountries.length) {
      const selectedMerchantsDetails =
        merchantCountriesForSelectedSalesChannel.filter((merchant) =>
          selectedMerchantCountries.includes(merchant.merchantCountryId)
        );
      // store only those merchant details in context which are selected in the merchant picker
      merchantContext.updateMerchantDetails(selectedMerchantsDetails);
    }
  }, [selectedMerchantCountries]);

  useEffect(() => {
    if (!adsManagerDetailsPageFlag) {
      // Remove all details page filters if we exit the details view
      removeAllDetailsPageFilters(userId, accountId);

      // saving ad level in the state only if it's not details page
      // to use in the breadcrumb 'Ads manager' navigation
      setAdLevelForBreadcrumb(identifiedAdLevel);
    }

    const sameAdLevel =
      prevPath &&
      checkIfAdLevelPathsAreOfSameLevel(prevPath, location) &&
      checkIfUrlPathsAreOfSameAdLevel(prevPath.pathname, location.pathname);

    if (!sameAdLevel) {
      getDataInspection(true);
    }

    const targetLevel = location.pathname.split('/').slice(-1).join('');
    if (targetLevel === 'keyword-targets') {
      switchLevelController(AdLevel.KeywordTargets);
    } else if (targetLevel === 'targets') {
      switchLevelController(AdLevel.Targets);
    } else if (targetLevel === 'product-targets') {
      switchLevelController(AdLevel.ProductTargets);
    } else if (targetLevel === 'product-ads') {
      switchLevelController(AdLevel.ProductAds);
    }
    setCurrentTableFilters(filtersFromStore);
  }, [location?.pathname, selectedSalesChannelId]);

  useEffect(() => {
    if (!isCurrentHeroMetricsLoadingRef.current) {
      nextLoadCurrentHeroMetricsRef.current();
      nextLoadCurrentHeroMetricsRef.current = noop;
    }
  }, [isCurrentHeroMetricsLoadingRef.current]);

  useEffect(() => {
    if (!isPreviousHeroMetricsLoadingRef.current) {
      nextPreviousHeroMetircsRef.current();
      nextPreviousHeroMetircsRef.current = noop;
    }
  }, [isPreviousHeroMetricsLoadingRef.current]);

  useEffect(() => {
    dispatch(actions.setFlywheelSalesChannelsInfo(flywheelSalesChannels));
  }, []);

  useEffect(() => {
    if (!isChatGPTUrl) {
      getSubscribedSalesChannelsAndAllMerchantCountries(flywheelSalesChannels)
        .then(({ merchantCountries, salesChannel }) => {
          setSubscribedSalesChannels(salesChannel);
          return merchantCountries;
        })
        .then(async (merchantCountries) => {
          const allPortfolios = await aoApiClient.getPortfolios(
            accountId,
            merchantCountries.map(
              (merchantCountry) => merchantCountry.merchantCountryId
            )
          );

          setPortfolios(sortPortfoliosByName(allPortfolios));
        })
        .catch((error) => {
          /**
           * TODO: #AO-259
           * Failing of request will make the entire page fail
           * The design team has plans to re-work on this issue specifically into the empty state message pages for AO
           * After that this needs to be integrated.
           */
        });
    }
    if (breadCrumbDetailsFromLocalStorage) {
      setAppHeaderTitle(breadCrumbDetailsFromLocalStorage);
    }
  }, [flywheelSalesChannels, isChatGPTUrl]);

  const handleGroupNamesApi = async () => {
    try {
      const currentlySelectedMerchantCountryId = isArray(
        selectedMerchantCountries
      )
        ? first(selectedMerchantCountries as string[])
        : selectedMerchantCountries;

      const allGroups = await aoApiClient.getAllGroups(
        accountId,
        currentlySelectedMerchantCountryId
      );

      setAoGroups(sortGroupsByName(allGroups));
      return Promise.resolve();
    } catch {}
  };

  useEffect(() => {
    if (!isChatGPTUrl) handleGroupNamesApi();
  }, [flywheelSalesChannels, selectedMerchantCountries, isChatGPTUrl]);

  const reloadGroupsData = () => {
    return handleGroupNamesApi();
  };

  const [currentTableFilters, setCurrentTableFilters] = useState<Filter[]>([]);

  useEffect(() => {
    if (
      selectedMerchantCountries &&
      selectedMerchantCountries.length > 0 &&
      selectedDateRange &&
      dateRangeRef.current
    ) {
      if (
        adTypeDropDownValue &&
        adLevelDropDownSelectedValues &&
        adLevelDropDownSelectedValues &&
        !isChatGPTUrl
      ) {
        if (isCurrentHeroMetricsLoadingRef.current === false) {
          loadCurrentHeroMetrics(currentTableFilters);
        } else {
          nextLoadCurrentHeroMetricsRef.current = () => {
            loadCurrentHeroMetrics(currentTableFilters);
          };
        }

        if (isPreviousHeroMetricsLoadingRef.current === false) {
          loadPreviousHeroMetrics(currentTableFilters);
        } else {
          nextPreviousHeroMetircsRef.current = () => {
            loadPreviousHeroMetrics(currentTableFilters);
          };
        }
      }
      getDataInspection(true);
    }
  }, [selectedDateRange, isChatGPTUrl]);

  const filtersFromStore = useSelector<AdOptimizationState, Filter[]>(
    ({ tableState }) => {
      return tableSelectors.getFiltersSelector()(
        tableState,
        getTableIdByAdLevel(adLevelDropDownSelectedValues || '')
      );
    }
  );

  useEffect(() => {
    const tableFiltersForSelectedAdLevel = filtersFromStore;
    if (
      adLevelDropDownSelectedValues &&
      isIdentifiedAdlevelOneOfTargets(adLevelDropDownSelectedValues) &&
      adsManagerDetailsPageFlag
    ) {
      storeDetailsTargetAdLevelToLocalStorage(
        userId,
        accountId,
        selectedSalesChannelId,
        adLevelDropDownSelectedValues as string
      );
    }
    if (
      selectedMerchantCountries &&
      selectedMerchantCountries.length > 0 &&
      adLevelDropDownSelectedValues &&
      !isChatGPTUrl &&
      adTypeDropDownValue
    ) {
      if (isCurrentHeroMetricsLoadingRef.current === false) {
        loadCurrentHeroMetrics(tableFiltersForSelectedAdLevel);
      } else {
        nextLoadCurrentHeroMetricsRef.current = () => {
          loadCurrentHeroMetrics(tableFiltersForSelectedAdLevel);
          setCurrentTableFilters(tableFiltersForSelectedAdLevel);
        };
      }

      if (isPreviousHeroMetricsLoadingRef.current === false) {
        loadPreviousHeroMetrics(tableFiltersForSelectedAdLevel);
      } else {
        nextPreviousHeroMetircsRef.current = () => {
          loadPreviousHeroMetrics(tableFiltersForSelectedAdLevel);
        };
      }
    }

    setCurrentTableFilters(tableFiltersForSelectedAdLevel);
  }, [adLevelDropDownSelectedValues, isChatGPTUrl, adTypeDropDownValue]);

  useEffect(() => {
    if (dateRangeRef.current) {
      const columns = mapDataInspectorColumnToApiColumn(
        tabCheckboxMetadata,
        adLevelDropDownSelectedValues || undefined
      );

      const maybeAdLevelColumns = adLevelDropDownSelectedValues
        ? [
            ...FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER[
              currentFlywheelSalesChannel
            ][adLevelDropDownSelectedValues],
            ...getFlywheelSettingsColumn(
              adLevelDropDownSelectedValues,
              isAIEnabledForOneOfTheSelectedMerchants,
              shouldShowSmartCampaigns(optimizelyContext),
              optimizelyContext.featureFlags[
                OptimizelyFlags.SmartAdGroupsBidOverrides
              ]
            ),
          ]
        : [];
      setApiColumnsWithAdLevel({
        columns: [...maybeAdLevelColumns, ...columns],
        adLevel: adLevelDropDownSelectedValues || undefined,
      });
    }
  }, [
    tabCheckboxMetadata,
    selectedDateRange,
    selectedMerchantCountries,
    adTypeDropDownValue,
    isAIEnabledForOneOfTheSelectedMerchants,
  ]);

  const toggleTableMode = () => {
    setTableMode((currentTableMode) =>
      currentTableMode === DualModes.View ? DualModes.Edit : DualModes.View
    );
  };

  const changeTableMode = (index: number) => {
    setTableMode(index === 0 ? DualModes.View : DualModes.Edit);
  };

  const currentTableId = getTableIdByAdLevel(adLevelDropDownSelectedValues);

  const clearFilters = (salesChannelId?: string) => {
    dispatch(
      tableActions.clearFilters({
        tableId: currentTableId,
      })
    );
    // Using salesChannelId also as a flag to removeFilters for simplicity.
    if (salesChannelId) {
      removeFiltersInLocalStorage(
        userId,
        accountId,
        salesChannelId,
        currentAdLevelId
      );
    }
    setCurrentTableFilters([]);
  };

  const merchantCountriesContext = useContext<MerchantCountriesContextState>(
    MerchantCountriesContext
  );

  const setSalesChannelSelection = (selectedChannelId: string) => {
    clearFilters(selectedChannelId);
    setSelectedSalesChannelId(selectedChannelId);
    dispatch(actions.setSelectedSalesChannel(selectedChannelId));
    storeSalesChannelToLocalStorage({
      userId,
      accountId,
      salesChannelId: selectedChannelId,
    });
    const currentSalesChannel =
      subscribedSalesChannels.find(
        (salesChannelInfo) =>
          salesChannelInfo.salesChannelId === selectedChannelId
      ) ?? subscribedSalesChannels[0];

    const merchantCountriesOfCurrentSalesChannel: MerchantCountry[] =
      getMerchantCountriesForSalesChannel(currentSalesChannel);
    setMerchantCountriesForSelectedSalesChannel(
      merchantCountriesOfCurrentSalesChannel
    );
  };

  const handleMerchantChange = (
    selectedMerchants: string | number | (string | number)[]
  ) => {
    let newSelectedMerchants = selectedMerchants as
      | string
      | (string | number)[];
    if (newSelectedMerchants.length === 0) {
      const firstMerchantFromList = first(selectedMerchantCountries);
      newSelectedMerchants = getMerchantAsArray(firstMerchantFromList);
    }
    if (!isEqual(newSelectedMerchants, selectedMerchantCountries)) {
      const merchant = merchantCountriesContext.merchantCountries?.find(
        (merchant) => merchant.merchantCountryId === newSelectedMerchants
      );
      if (merchant?.salesChannelId) {
        setSalesChannelSelection(merchant.salesChannelId);
      }
      const currentFilterFields = currentTableFilters.map(
        (filter) => filter.field
      );
      if (
        currentFilterFields.includes(CAMPAIGNS_API_COLUMN_NAME.MerchantName)
      ) {
        const updatedFiltersList = currentTableFilters.filter(
          (filter) => filter.field !== CAMPAIGNS_API_COLUMN_NAME.MerchantName
        );
        dispatch(
          tableActions.updateFilters({
            tableId: currentTableId,
            filters: updatedFiltersList,
            replace: true,
          })
        );
        setCurrentTableFilters(updatedFiltersList);
        storeFiltersInBrowserStorage(updatedFiltersList);
      }
      if (merchant && merchant.salesChannelId) {
        updateDataAvailabilityInfo(
          isArray(newSelectedMerchants)
            ? (newSelectedMerchants as string[])
            : [newSelectedMerchants],
          merchant.salesChannelId
        );
      }
      setSelectedMerchantCountries(newSelectedMerchants);
      const currentUserId = userContext.userInfo.userDetails?.id || '';
      storeMerchantToLocalStorage(
        {
          userId: currentUserId,
          accountId,
          salesChannelId: selectedSalesChannelId,
        },
        newSelectedMerchants
      );
    }
  };

  const checkAvailability = async () => {
    const request: any = {
      merchantCountries: [
        {
          merchantCountryId: isArray(selectedMerchantCountries)
            ? selectedMerchantCountries?.[0] || ''
            : selectedMerchantCountries,
          salesChannelId: selectedSalesChannelId,
        },
      ],
    };
    return campaignFlexibilityClient.getMerchantAvailability(
      accountId,
      request
    );
  };

  useEffect(() => {
    if (selectedMerchantCountries && selectedSalesChannelId) {
      checkAvailability()
        .then((res) => {
          setDisCheckavailability(
            res?.availability?.[0]?.status !== AvailabilityStatus.Ready
          );
        })
        .catch((err) => console.error(err));
    }
  }, [selectedMerchantCountries, selectedSalesChannelId]);
  const handleDateRangeChange = (
    newStartDate: string,
    newEndDate: string,
    isCustom?: boolean
  ) => {
    const DATE_FORMAT = 'yyyy-MM-dd';
    const startDateInLuxon = DateTime.fromFormat(newStartDate, DATE_FORMAT);
    const endDateInLuxon = DateTime.fromFormat(newEndDate, DATE_FORMAT);

    if (isCustom) {
      const customDateRangeDataTestId = `date_range_${getDatePresentRangeForTracking(
        endDateInLuxon.diff(startDateInLuxon).milliseconds /
          MILLISECONDS_TO_DAYS +
          1
      )}`;
      setDateRangeDataTestId(`ao_custom_${customDateRangeDataTestId}`);
    }

    const dateRangeFromStart = getDatePresentRangeForTracking(
      Math.floor(
        Math.abs(startDateInLuxon.diffNow().milliseconds / MILLISECONDS_TO_DAYS)
      )
    );

    setDateRangeDataTestIdForStartDate(
      `ao_start_date_${dateRangeFromStart}_ago`
    );

    const newDateRange = {
      ...selectedDateRange,
      initialStartDate: startDateInLuxon,
      initialEndDate: endDateInLuxon,
    };
    setSelectedDateRange(newDateRange);
    storeStartDateEndDateInLocalStorage(
      { userId, accountId, salesChannelId: selectedSalesChannelId },
      newDateRange
    );
  };

  const updateDataAvailabilityInfo = async (
    merchantIds: string[],
    salesChannel: SalesChannelId
  ) => {
    if (merchantIds.length === 0) return;

    const dataAvailabilityInfoResponse =
      aoDataSyncInfo?.syncPerMerchantIds?.filter((d) =>
        merchantIds.includes(d.merchantCountryId)
      ) ?? [];

    const syncPerMerchantIds = dataAvailabilityInfoResponse.map(
      (syncPerMerchant) => {
        return {
          merchantCountryId: syncPerMerchant.merchantCountryId,
          latestAvailableDate: DateTime.fromISO(
            syncPerMerchant.latestAvailableDate || ''
          ).toJSDate(),
          earliestAvailableDate: DateTime.fromISO(
            syncPerMerchant.earliestAvailableDate || ''
          ).toJSDate(),
          lastSyncedAt: DateTime.fromISO(
            syncPerMerchant.lastSyncedAt || ''
          ).toJSDate(),
        };
      }
    );

    const permittedDateRange: DateRange =
      runningTests && currentDateRange
        ? currentDateRange
        : getPermittedDateRange(
            syncPerMerchantIds as DataAvailabilityInfo[],
            merchantIds
          );

    const previouslySelectedStartDateEndDate = runningTests
      ? undefined
      : getStartDateEndDateFromLocalStorage({
          userId,
          accountId,
          salesChannelId: salesChannel,
        });

    let updatedDateRange = {
      ...permittedDateRange,
    };

    if (
      previouslySelectedStartDateEndDate?.initialEndDate &&
      previouslySelectedStartDateEndDate?.initialStartDate &&
      previouslySelectedStartDateEndDate?.initialStartDate <
        previouslySelectedStartDateEndDate?.initialEndDate
    ) {
      updatedDateRange = {
        ...permittedDateRange,
        ...previouslySelectedStartDateEndDate,
      };
    }

    setSelectedDateRange(updatedDateRange);
    storeStartDateEndDateInLocalStorage(
      { userId, accountId, salesChannelId: salesChannel },
      {
        initialStartDate: updatedDateRange.initialStartDate,
        initialEndDate: updatedDateRange.initialEndDate,
      }
    );

    dateRangeRef.current = true;
  };

  const getParamsFromUrl = (urlQuery: string) => {
    const urlSearchParams = new URLSearchParams(decodeURIComponent(urlQuery));
    return {
      urlParamsSalesChannelId: urlSearchParams.get(SALES_CHANNEL_ID),
      previousSelectedMerchantIds: urlSearchParams
        .get(MERCHANT_COUNTRY_IDS)
        ?.split(','),
      dataRange: {
        startDate: urlSearchParams.get(START_DATE),
        endDate: urlSearchParams.get(END_DATE),
      },
    };
  };

  const getSubscribedSalesChannelsAndAllMerchantCountries = async (
    currentFlywheelSalesChannels: AllSalesChannel[]
  ): Promise<{
    salesChannel: SalesChannel[];
    merchantCountries: MerchantCountry[];
  }> => {
    const merchantCountriesInfoItems =
      merchantCountriesContext.merchantCountries || [];
    const allSubscribedSalesChannels: SalesChannel[] =
      getSalesChannelsWithMerchantCountriesInfo(
        merchantCountriesInfoItems,
        currentFlywheelSalesChannels
      );

    return {
      salesChannel: sortSalesChannelsByName(allSubscribedSalesChannels),
      merchantCountries: merchantCountriesInfoItems,
    };
  };

  const initialSalesChannelSelectionHandler = (
    currentSalesChannelId: string
  ) => {
    setSelectedSalesChannelId(currentSalesChannelId);
    dispatch(actions.setSelectedSalesChannel(currentSalesChannelId));
    storeSalesChannelToLocalStorage({
      userId,
      accountId,
      salesChannelId: currentSalesChannelId,
    });
  };

  useEffect(() => {
    const {
      urlParamsSalesChannelId,
      previousSelectedMerchantIds,
      dataRange: { startDate, endDate },
    } = getParamsFromUrl(location.search);
    if (subscribedSalesChannels.length > 0) {
      const { salesChannelFromPathname } = getDropdownValuesFromPathname(
        location.pathname
      );

      const salesChannelIdFromPathname = flywheelSalesChannels.find(
        (salesChannel) => salesChannel.name === salesChannelFromPathname
      )?.id;

      const selectedSalesChannelIdInBrowserStorage =
        salesChannelIdFromPathname ||
        getSalesChannelFromLocalStorage(userId, accountId);

      const selectedSalesChannelInfo =
        findSalesChannelByChannelId(
          subscribedSalesChannels,
          urlParamsSalesChannelId || selectedSalesChannelIdInBrowserStorage
        ) ?? first<SalesChannel>(subscribedSalesChannels);

      if (selectedSalesChannelInfo?.salesChannelId) {
        const currentSelectedSalesChannelId: string =
          selectedSalesChannelInfo.salesChannelId;
        initialSalesChannelSelectionHandler(currentSelectedSalesChannelId);
        const merchantCountriesOfCurrentSalesChannel =
          selectedSalesChannelInfo.merchantCountries;
        setMerchantCountriesForSelectedSalesChannel(
          merchantCountriesOfCurrentSalesChannel
        );

        const currentFlywheelChannel = getFlywheelChannelFromSalesChannelId(
          currentSelectedSalesChannelId,
          subscribedSalesChannels
        );
        const flywheelMetchantIdByType = (
          FLYWHEEL_CHANNEL_TO_MERCHANT_PICKER_TYPE_MAPPER[
            currentFlywheelChannel
          ] === MerchantPickerType.Single
            ? first(previousSelectedMerchantIds)!
            : previousSelectedMerchantIds
        ) as MerchantCountryId;
        const selectedMerchantIds = previousSelectedMerchantIds
          ? flywheelMetchantIdByType
          : getSelectedMerchantCountries(selectedSalesChannelInfo);
        setInitialStateForMerchantPicker(
          selectedMerchantIds,
          currentSelectedSalesChannelId
        );

        setInitialStateForDataAvailabilityInfo(
          selectedSalesChannelInfo,
          selectedMerchantIds
        );

        if (startDate && endDate) {
          handleDateRangeChange(startDate, endDate);
        }
      }
    }
  }, [subscribedSalesChannels, aoDataSyncInfo]);

  const setInitialStateForMerchantPicker = (
    selectedMerchantIds: MerchantCountryId,
    currentSalesChannelId: string
  ) => {
    setSelectedMerchantCountries(selectedMerchantIds);
    storeMerchantToLocalStorage(
      { userId, accountId, salesChannelId: currentSalesChannelId },
      selectedMerchantIds
    );
  };

  const setInitialStateForDataAvailabilityInfo = (
    selectedSalesChannel: SalesChannel,
    selectedMerchantIds: MerchantCountryId
  ) => {
    updateDataAvailabilityInfo(
      Array.isArray(selectedMerchantIds)
        ? selectedMerchantIds.map((merchantId) => merchantId.toString())
        : [selectedMerchantIds],
      selectedSalesChannel.salesChannelId
    );
  };

  const getSelectedMerchantCountries = (
    selectedSalesChannel: SalesChannel
  ): MerchantCountryId => {
    const currentSalesChannelId = selectedSalesChannel.salesChannelId;
    const merchantCountriesOfCurrentSalesChannel =
      selectedSalesChannel.merchantCountries;
    let selectedMerchantIds: MerchantCountryId = [];

    const merchantIdsFromLocalStorage = getMerchantFromLocalStorage({
      userId,
      accountId,
      salesChannelId: currentSalesChannelId,
    });

    const merchantCountryIdsFromSelectedSalesChannel =
      selectedSalesChannel.merchantCountries.map(
        (country) => country.merchantCountryId
      );

    if (merchantIdsFromLocalStorage) {
      if (isArray(merchantIdsFromLocalStorage)) {
        const filteredMerchantCountryIdsFromLocalStorage =
          merchantIdsFromLocalStorage.filter(
            (merchantCountryId: string | number) =>
              merchantCountryIdsFromSelectedSalesChannel.includes(
                String(merchantCountryId)
              )
          );
        selectedMerchantIds =
          filteredMerchantCountryIdsFromLocalStorage.length > 0
            ? filteredMerchantCountryIdsFromLocalStorage
            : merchantCountryIdsFromSelectedSalesChannel;
      } else {
        selectedMerchantIds =
          merchantCountryIdsFromSelectedSalesChannel.includes(
            merchantIdsFromLocalStorage
          )
            ? merchantIdsFromLocalStorage
            : first(merchantCountryIdsFromSelectedSalesChannel) || '';
      }
    } else {
      const currentFlywheelChannel = getFlywheelChannelFromSalesChannelId(
        currentSalesChannelId,
        subscribedSalesChannels
      );
      selectedMerchantIds = getMerchantCountryIdsForInitialLoad(
        merchantCountriesOfCurrentSalesChannel,
        currentFlywheelChannel
      );
    }
    return selectedMerchantIds;
  };

  const getShouldShowSbaFlag = () => {
    return selectedFlywheelChannel === FlywheelSalesChannel.Walmart;
  };

  const getDataInspection = async (pathNameChangeFlag: boolean) => {
    const requiredMerchantCountries =
      merchantCountriesForSelectedSalesChannel.filter((merchantCountry) =>
        isArray(selectedMerchantCountries)
          ? selectedMerchantCountries.includes(
              merchantCountry.merchantCountryId
            )
          : merchantCountry.merchantCountryId === selectedMerchantCountries
      );

    const requiredMerchantCountryCode = first(requiredMerchantCountries)
      ?.country as MerchantCountryCode;

    const requiredMcId = first(requiredMerchantCountries)?.merchantCountryId;

    const shouldShowSBA = getShouldShowSbaFlag();

    const areSpecificTargetTypesRequired = shouldFetchSpecificTargetTypes(
      identifiedAdLevel,
      adTypeDropDownValue,
      selectedFlywheelChannel,
      adsManagerDetailsPageFlag
    );

    const specificTargetTypes: AdLevel[] = [];
    let shouldShowKeywordTargets = true;

    if (areSpecificTargetTypesRequired && requiredMcId) {
      const targetTypesResponse = await aoApiClient.getTargetTypes(
        accountId,
        requiredMcId,
        TARGET_TYPE_API_TO_AD_LEVEL_MAPPER[identifiedAdLevel],
        currentAdLevelId || ''
      );
      targetTypesResponse.targetTypes.forEach((responseTargetType) => {
        const adLevelBasedOnTargetType = adTypeDropDownValue
          ? TARGET_TYPE_API_RESPONSE_TO_AD_LEVEL_MAPPER[adTypeDropDownValue][
              responseTargetType
            ]
          : TARGET_TYPE_API_RESPONSE_TO_AD_LEVEL_MAPPER[
              AdType.SponsoredProducts
            ][responseTargetType];

        shouldShowKeywordTargets =
          !(
            selectedFlywheelChannel === FlywheelSalesChannel.Amazon &&
            identifiedAdLevel === AdLevel.Campaigns &&
            adTypeDropDownValue === AdType.SponsoredProducts &&
            adsManagerDetailsPageFlag
          ) || responseTargetType !== 'Keyword Targeting';

        if (shouldShowKeywordTargets) {
          specificTargetTypes.push(adLevelBasedOnTargetType);
        }
      });
    }

    try {
      setDataInspectionRequest(AsyncRequestLoading());
      const dataInspectionArray = await aoApiClient.getDataInspection({
        salesChannel: currentFlywheelSalesChannel,
        adsManagerDetailsPageFlag: adsManagerDetailsPageFlag,
        adLevel: identifiedAdLevel,
        showSponsoredVideos:
          optimizelyContext.featureFlags[OptimizelyFlags.ShowSponsoredVideos],
      });
      const dropdownMetadata = getDropDownMetaDataForDataInspection({
        intl,
        dataInspectionList: dataInspectionArray,
        countryCode: requiredMerchantCountryCode,
        specificTargetTypes,
        salesChannel: currentFlywheelSalesChannel,
        targetingType: getTargetingTypeParam(targetingTypeParam),
        shouldShowSBA,
        shouldHideKeywordTargets: !shouldShowKeywordTargets,
        adsManagerDetailsPageFlag,
        identifiedAdLevel,
        urlPathNameList,
      });

      selectedSalesChannelId &&
        setDefaultValueForDropdowns(dropdownMetadata, pathNameChangeFlag);
      setDataInspectionRequest(AsyncRequestCompleted(dropdownMetadata));
    } catch (error) {
      setDataInspectionRequest(AsyncRequestFailed(undefined));
    }
  };

  const updateLocalStorageChannelId = () => {
    storeSalesChannelToLocalStorage({
      userId,
      accountId,
      salesChannelId: selectedSalesChannelId,
    });
  };

  const setDefaultValueForDropdowns = (
    dropdownMetadata: DataInspection[],
    pathNameChangeFlag: boolean
  ) => {
    if (
      (dropdownMetadata[0]?.child[0] &&
        !adTypeDropDownValue &&
        !adLevelDropDownSelectedValues) ||
      pathNameChangeFlag
    ) {
      let currentPath = location?.pathname || '';
      if (last(currentPath.split('')) === '/') {
        currentPath = currentPath.slice(0, -1);
      }

      const { adLevelFromPathname, adTypeFromPathname } =
        getDropdownValuesFromPathname(currentPath);

      const adTypeFromBrowserStorage = getAdTypeFromLocalStorage({
        userId,
        accountId,
        salesChannelId: selectedSalesChannelId,
      });

      let adTypeInitialValue = adTypeFromBrowserStorage;

      if (firstPageLoad && adTypeFromPathname) {
        adTypeInitialValue = adTypeFromPathname;
      }

      let currentAdTypeDropdownValue =
        AD_TYPE_DEFAULT_VALUE[currentFlywheelSalesChannel];

      const isAdTypeInBrowserStorageAvailableInMetadata =
        isAdTypeAvailableInMetadata(
          dropdownMetadata,
          adTypeInitialValue as AdType
        );

      if (adTypeInitialValue && isAdTypeInBrowserStorageAvailableInMetadata) {
        currentAdTypeDropdownValue = adTypeInitialValue as AdType;

        storeAdTypeToLocalStorage(
          userId,
          accountId,
          selectedSalesChannelId,
          adTypeInitialValue
        );
      } else {
        storeAdTypeToLocalStorage(
          userId,
          accountId,
          selectedSalesChannelId,
          currentAdTypeDropdownValue
        );
      }
      setAdTypeDropDownValue(currentAdTypeDropdownValue);

      const adLevelInBrowserStorage = getAdLevelFromLocalStorage(
        userId,
        accountId,
        selectedSalesChannelId
      );

      let adLevelInitialValue = adLevelInBrowserStorage;

      if (firstPageLoad && adLevelFromPathname) {
        adLevelInitialValue = adLevelFromPathname;
      }

      const pageType = adLevelInitialValue
        ? adLevelInitialValue
        : currentPath &&
          ADS_MANAGER_PAGE_TO_AD_LEVEL_MAPPER[
            currentPath as ADS_MANAGER_AD_LEVEL_PAGES
          ];

      const adLevelFromSession =
        getDetailsPageSelectedAdLevelFromSessionStorage(userId, accountId);

      const selectedAdLevel =
        adsManagerDetailsPageFlag && adLevelFromSession
          ? adLevelFromSession
          : pageType;

      const isAdLevelInBrowserStorageAvailableInMetadata =
        isAdLevelAvailableInMetadata(
          dropdownMetadata,
          currentAdTypeDropdownValue,
          adLevelInitialValue as AdLevel
        );
      const dropDownDataForCurrentAdType = dropdownMetadata.find(
        (data) => data.parent.value === currentAdTypeDropdownValue
      );
      const selectedAdLevelDataFromDropdownMetaData =
        dropDownDataForCurrentAdType?.child.find(
          ({ value }) => value === selectedAdLevel
        );
      const currentAdTypeSpecificDropdownMetaData = dropdownMetadata.filter(
        (adTypeMetaData: DataInspection) =>
          adTypeMetaData.parent.value === currentAdTypeDropdownValue
      );
      const adLevelAsPerPage = fromNullable(
        selectedAdLevelDataFromDropdownMetaData
      )
        .map((someVariable) => someVariable.value)
        .getOrElse(currentAdTypeSpecificDropdownMetaData[0].child[0].value);

      // check if adLevel is present in pathName or Browser storage
      if (adLevelInitialValue && isAdLevelInBrowserStorageAvailableInMetadata) {
        if (
          !adLevelFromSession ||
          (currentAdTypeDropdownValue === AdType.SponsoredBrands &&
            adsManagerDetailsPageFlag &&
            !ADS_MANAGER_DETAILS_PAGE_AD_LEVEL_MAPPER[
              identifiedAdLevel
            ].includes(selectedAdLevel as AdLevel))
        ) {
          setAdLevelDropDownSelectedValues(adLevelInitialValue as AdLevel);
        } else {
          setAdLevelDropDownSelectedValues(selectedAdLevel as AdLevel);
        }

        if (!adsManagerDetailsPageFlag) {
          storeAdLevelToLocalStorage(
            userId,
            accountId,
            selectedSalesChannelId,
            adLevelInitialValue
          );
        }

        const adTypeForUrl =
          AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[currentAdTypeDropdownValue];

        const isChatGPTUrl = location.pathname.includes('create-chatgpt');

        const salesChannelForUrl = currentFlywheelSalesChannel;

        const urlToNavigate = AD_LEVEL_TO_ADS_MANAGER_PAGE_MAPPER[
          adLevelInitialValue as AdLevel
        ].find(
          (pageUrl: string) =>
            pageUrl.includes(salesChannelForUrl) &&
            pageUrl.includes(adTypeForUrl)
        );

        if (
          !adsManagerDetailsPageFlag &&
          urlToNavigate &&
          urlToNavigate !== location.pathname &&
          !isChatGPTUrl
        ) {
          navigate(isChatGPTUrl ? location.pathname : urlToNavigate, {
            state: { prevPath: location },
          });
          updateLocalStorageChannelId();
        }
      } else {
        setAdLevelDropDownSelectedValues(adLevelAsPerPage);
        if (!adsManagerDetailsPageFlag) {
          storeAdLevelToLocalStorage(
            userId,
            accountId,
            selectedSalesChannelId,
            adLevelAsPerPage
          );
        }
      }
      const requiredAdLevelCheckboxData = getAdLevelColumnManagerCheckboxData(
        tabsCheckboxesForAllAdLevels,
        currentFlywheelSalesChannel,
        adTypeDropDownValue || currentAdTypeDropdownValue,
        adLevelAsPerPage
      );

      setTabCheckboxMetadata(requiredAdLevelCheckboxData);
    }

    if (firstPageLoad) {
      setFirstPageLoad(false);
    }
  };

  const onAdLevelChange = (newAdLevel: string) => {
    const tabCheckboxValues = getAdLevelColumnManagerCheckboxData(
      tabsCheckboxesForAllAdLevels,
      currentFlywheelSalesChannel,
      adTypeDropDownValue,
      newAdLevel as AdLevel
    );
    if (!isEmpty(tabCheckboxValues)) {
      const updatedTabsCheckboxesForAllAdLevels =
        getTabCheckboxValuesAfterColumnSelectionIsUpdated(
          adTypeDropDownValue!,
          newAdLevel as AdLevel,
          undefined,
          tabCheckboxValues
        );
      setTabCheckboxMetadata(tabCheckboxValues);
      setTabsCheckboxesForAllAdLevels(updatedTabsCheckboxesForAllAdLevels);
      storeTabCheckboxColumnSelectionInfoInLocalStorage(
        userId,
        accountId,
        updatedTabsCheckboxesForAllAdLevels
      );
    }
  };

  const onAdTypeChange = (newAdType: string | number | (string | number)[]) => {
    const adLevelsInAdType = getAdLevelsWithAdType(newAdType as string);

    if (adLevelsInAdType.length === 0) return;

    const firstAdLevel = adLevelsInAdType[0];

    onAdLevelChange(firstAdLevel.value);
    onClickApplyChanges(firstAdLevel.value, newAdType as string);
  };

  const onLevelControllerChange = (newAdLevelValue: string) => {
    if (
      tableMode === DualModes.Edit &&
      saveChangesFlagContext.saveChangesData.editDataFlag
    ) {
      saveChangesFlagContext.updateSaveChangesData({
        ...saveChangesFlagContext.saveChangesData,
        saveChangesModalIsOpen: true,
        aoAdLevel: newAdLevelValue,
      });
    } else {
      switchLevelController(newAdLevelValue);
    }
  };

  const switchLevelController = (adLevel: string) => {
    onAdLevelChange(adLevel);
    onClickApplyChanges(adLevel, adTypeDropDownValue as string);
    setTableMode(DualModes.View);
  };

  const onClickApplyChanges = (
    newAdLevelValue: string,
    newAdTypeValue: string
  ) => {
    if (!isEqual(newAdTypeValue, adTypeDropDownValue)) {
      clearFilters(selectedSalesChannelId);
    }

    !adsManagerDetailsPageFlag &&
      removeBreadCrumbDetailsInLocalStorage(userId, accountId);

    setAdLevelDropDownSelectedValues(newAdLevelValue as AdLevel);

    if (adsManagerDetailsPageFlag) {
      storeDetailsPageSelectedAdLevelToSessionStorage(
        userId,
        accountId,
        newAdLevelValue
      );
    }

    setAdTypeDropDownValue(newAdTypeValue as AdType);

    if (!adsManagerDetailsPageFlag) {
      storeAdTypeToLocalStorage(
        userId,
        accountId,
        selectedSalesChannelId,
        newAdTypeValue
      );
      storeAdLevelToLocalStorage(
        userId,
        accountId,
        selectedSalesChannelId,
        newAdLevelValue
      );
    }

    const adTypeForUrl =
      AD_TYPE_VALUE_TO_AD_TYPE_IN_URL_MAPPER[newAdTypeValue as AdType];

    const currentSalesChannelInfo = flywheelSalesChannels.find(
      (salesChannel) => salesChannel.id === selectedSalesChannelId
    );

    const currentSalesChannelName = currentSalesChannelInfo?.name || '';

    const maybeNewAdLevelUrl = AD_LEVEL_TO_ADS_MANAGER_PAGE_MAPPER[
      newAdLevelValue as AdLevel
    ].find(
      (pageUrl) =>
        pageUrl.includes(currentSalesChannelName) &&
        pageUrl.includes(adTypeForUrl)
    );

    // Navigate only if it's not a details page and current URL and new url are not same.
    if (
      !adsManagerDetailsPageFlag &&
      location.pathname !== maybeNewAdLevelUrl
    ) {
      fromNullable(maybeNewAdLevelUrl)
        .map((newAdLevelUrl) => {
          navigate(newAdLevelUrl, { state: { prevPath: location } });
          updateLocalStorageChannelId();
          return undefined;
        })
        .toUndefined();
    }
  };

  const onColumnSelectionChange = (currentColumns: string[]) => {
    const updatedTabsCheckboxesForAllAdLevels =
      getTabCheckboxValuesAfterColumnSelectionIsUpdated(
        adTypeDropDownValue!,
        adLevelDropDownSelectedValues,
        currentColumns
      );
    const updatedLevel = getAdLevelColumnManagerCheckboxData(
      updatedTabsCheckboxesForAllAdLevels,
      currentFlywheelSalesChannel,
      adTypeDropDownValue,
      adLevelDropDownSelectedValues as AdLevel
    );
    setTabCheckboxMetadata(updatedLevel);
    setTabsCheckboxesForAllAdLevels(updatedTabsCheckboxesForAllAdLevels);
    storeTabCheckboxColumnSelectionInfoInLocalStorage(
      userId,
      accountId,
      updatedTabsCheckboxesForAllAdLevels
    );
  };

  const getTabCheckboxValuesAfterColumnSelectionIsUpdated = (
    newAdTypeValue: string,
    newAdLevelValue: string,
    currentColumns?: string[],
    newTabCheckboxesValues?: TabCheckbox
  ) => {
    const getCheckBoxesValuesByColumns = (
      adLevelConfig: DataInspectionAdLevelConfigProps
    ) =>
      currentColumns
        ? getNewTabCheckboxValues(
            currentColumns,
            adLevelConfig,
            adLevelConfig.columns
          )
        : newTabCheckboxesValues!;

    return produce(tabsCheckboxesForAllAdLevels, (draft) => {
      draft[currentFlywheelSalesChannel] = tabsCheckboxesForAllAdLevels[
        currentFlywheelSalesChannel
      ].map((adTypeConfig) => {
        let copy = cloneDeep(adTypeConfig);

        if (adTypeConfig.adType === newAdTypeValue) {
          copy = {
            adType: newAdTypeValue,
            config: adTypeConfig.config.map((adLevelConfig) => ({
              ...adLevelConfig,
              columns:
                adLevelConfig.adLevel === newAdLevelValue
                  ? getCheckBoxesValuesByColumns(adLevelConfig)
                  : adLevelConfig.columns,
            })),
          };
        }
        return copy;
      });
    });
  };

  const heroMetricsAdLevelDetailsFilter = () => {
    if (adsManagerDetailsPageFlag) {
      switch (identifiedAdLevel) {
        case AdLevel.Campaigns:
          return campaignDetailFilters;
        case AdLevel.AdGroups:
          return adGroupDetailFilters;
        default:
          return [];
      }
    }
    return [];
  };

  const loadCurrentHeroMetrics = (filters: Filter[]) => {
    let endPoint: AdLevelApiEndpoint = AdLevelApiEndpoint.Campaigns;
    if (adLevelDropDownSelectedValues) {
      endPoint = ADLEVEL_TO_ADLEVEL_API_MAPPER[adLevelDropDownSelectedValues];
    }

    // TODO: Need a better solution for this. This condition is a hack from preventing incorrect aggregation calls being made from Campaign details and Ad group details page.
    if (
      (endPoint === AdLevelApiEndpoint.Campaigns &&
        heroMetricsAdLevelDetailsFilter()
          .map((filter) => filter.field)
          .includes(ADLEVEL_DETAILS_FILTER_FIELD.campaignsId)) ||
      (endPoint === AdLevelApiEndpoint.Adgroups &&
        heroMetricsAdLevelDetailsFilter()
          .map((filter) => filter.field)
          .includes(ADLEVEL_DETAILS_FILTER_FIELD.adGroupsId))
    ) {
      return;
    }
    setHeroMetricsCurrentMetricRequest(AsyncRequestLoading());

    const currentMetricRequest: PerformanceMetricsRequest = {
      aggregationPeriodType: HeroMetricsAggregationPeriodType.Current,
      performanceFields: currentHerometricsPerformanceFieldsV2,
      filters: [...filters, ...heroMetricsAdLevelDetailsFilter()],
      targetType: targetTypeRequestParameter,
      ...commonRequestFields,
    };
    isCurrentHeroMetricsLoadingRef.current = true;
    aoApiClient
      .getMetrics(
        accountId,
        endPoint,
        ALL_AD_LEVELS_FILTER_FILED_MAPPER,
        getTransformedFilters(intl, adLevelDropDownSelectedValues as AdLevel)
      )(currentMetricRequest)
      .then((currentMetricResponse) => {
        isCurrentHeroMetricsLoadingRef.current = false;
        setHeroMetricsCurrentMetricRequest(
          AsyncRequestCompleted(currentMetricResponse)
        );
      })
      .catch((error) => {
        isCurrentHeroMetricsLoadingRef.current = false;
        setHeroMetricsCurrentMetricRequest(AsyncRequestFailed(error));
      });
  };

  const loadPreviousHeroMetrics = (filters: Filter[]) => {
    let endPoint: AdLevelApiEndpoint = AdLevelApiEndpoint.Campaigns;
    if (adLevelDropDownSelectedValues) {
      endPoint = ADLEVEL_TO_ADLEVEL_API_MAPPER[adLevelDropDownSelectedValues];
    }

    // TODO: Need a better solution for this. This condition is a hack from preventing incorrect aggregation calls being made from Campaign details and Ad group details page.
    if (
      (endPoint === AdLevelApiEndpoint.Campaigns &&
        heroMetricsAdLevelDetailsFilter()
          .map((filter) => filter.field)
          .includes(ADLEVEL_DETAILS_FILTER_FIELD.campaignsId)) ||
      (endPoint === AdLevelApiEndpoint.Adgroups &&
        heroMetricsAdLevelDetailsFilter()
          .map((filter) => filter.field)
          .includes(ADLEVEL_DETAILS_FILTER_FIELD.adGroupsId))
    ) {
      return;
    }
    setHeroMetricsPreviousMetricRequest(AsyncRequestLoading());

    const previoustMetricRequest: PerformanceMetricsRequest = {
      aggregationPeriodType: HeroMetricsAggregationPeriodType.Previous,
      performanceFields: currentHerometricsPerformanceFieldsV2,
      filters: [...filters, ...heroMetricsAdLevelDetailsFilter()],
      targetType: targetTypeRequestParameter,
      ...commonRequestFields,
    };
    isPreviousHeroMetricsLoadingRef.current = true;
    aoApiClient
      .getMetrics(
        accountId,
        endPoint,
        ALL_AD_LEVELS_FILTER_FILED_MAPPER,
        getTransformedFilters(intl, adLevelDropDownSelectedValues as AdLevel)
      )(previoustMetricRequest)
      .then((previousMetricResponse) => {
        isPreviousHeroMetricsLoadingRef.current = false;
        setHeroMetricsPreviousMetricRequest(
          AsyncRequestCompleted(previousMetricResponse)
        );
      })
      .catch((error) => {
        isPreviousHeroMetricsLoadingRef.current = false;
        setHeroMetricsPreviousMetricRequest(AsyncRequestFailed(error));
      });
  };

  const selectedSalesChannelName = useMemo(
    () =>
      subscribedSalesChannels.find(
        (salesChannelInfo) =>
          salesChannelInfo.salesChannelId === selectedSalesChannelId
      )?.name || '',
    [subscribedSalesChannels, selectedSalesChannelId]
  );

  const mandatoryCampaignColumnsChannelSettings =
    selectedSalesChannelName && adTypeDropDownValue
      ? MANDATORY_CAMPAIGN_COLUMNS_CHANNEL_SETTINGS[
          selectedSalesChannelName as FlywheelSalesChannel
        ][adTypeDropDownValue]
      : [];

  const mandatoryCampaignColumnsCampaignDetails =
    selectedSalesChannelName && adTypeDropDownValue
      ? MANDATORY_CAMPAIGN_COLUMNS_CAMPAIGN_DETAILS[
          selectedSalesChannelName as FlywheelSalesChannel
        ][adTypeDropDownValue]
      : [];

  const commonRequestFields = {
    startDate: selectedDateRange.initialStartDate.toFormat(REQUEST_DATE_FORMAT),
    endDate: selectedDateRange.initialEndDate.toFormat(REQUEST_DATE_FORMAT),
    merchantCountryIds: isArray(selectedMerchantCountries)
      ? (selectedMerchantCountries as string[])
      : [selectedMerchantCountries],
    channelId: selectedSalesChannelName,
    advertisingType: getRequestAdvertisingType(adTypeDropDownValue!),
    salesChannelId: selectedSalesChannelId,
    merchantType: selectedMerchantType,
  };

  const currentTabCheckboxData = tabsCheckboxesForAllAdLevels[
    selectedFlywheelChannel
  ]
    ?.find(({ adType }) => adType === adTypeDropDownValue)
    ?.config?.find(
      ({ adLevel }) => adLevel === adLevelDropDownSelectedValues
    )?.columns;

  const CAMPAIGN_COLUMNS: string[] = [
    CAMPAIGNS_API_COLUMN_NAME.CampaignName,
    ...(currentTabCheckboxData
      ? getAllApiColumns(currentTabCheckboxData, AdLevel.Campaigns)
      : []),
  ];

  const isAiDisabledForOneOftheSelectedMerchant = useMemo(() => {
    if (
      selectedFlywheelChannel === FlywheelSalesChannel.Amazon &&
      merchantsBySalesChannel
    ) {
      const merchantCountryInfo = merchantsBySalesChannel
        .find(
          ({ flywheelSalesChannel }) =>
            flywheelSalesChannel === selectedFlywheelChannel
        )
        ?.merchants.find(({ id }) => selectedMerchantCountries === id);
      if (
        merchantCountryInfo?.aiEnabled === false &&
        merchantCountryInfo?.adConnectionStatus === ConnectionStatus.Connected
      ) {
        return true;
      }
    }

    if (
      selectedFlywheelChannel === FlywheelSalesChannel.Walmart &&
      merchantsBySalesChannel
    ) {
      return merchantsBySalesChannel
        .find(
          ({ flywheelSalesChannel }) =>
            flywheelSalesChannel === selectedFlywheelChannel
        )
        ?.merchants.filter(({ id }) => selectedMerchantCountries.includes(id))
        .some(
          ({ adConnectionStatus, aiEnabled }) =>
            aiEnabled === false &&
            adConnectionStatus === FAMConnectionStatus.Active
        );
    }

    return false;
  }, [
    selectedSalesChannelId,
    merchantsBySalesChannel,
    selectedMerchantCountries,
  ]);

  const isProductConnectedForSelectedMerchant = useMemo(() => {
    if (
      selectedFlywheelChannel === FlywheelSalesChannel.Amazon &&
      merchantsBySalesChannel
    ) {
      const merchantCountryInfo = merchantsBySalesChannel
        .find(
          ({ flywheelSalesChannel }) =>
            flywheelSalesChannel === selectedFlywheelChannel
        )
        ?.merchants.find(({ id }) => selectedMerchantCountries === id);
      if (
        merchantCountryInfo?.productConnectionStatus === ConnectionStatus.Active
      ) {
        return true;
      }
    }

    if (
      selectedFlywheelChannel === FlywheelSalesChannel.Walmart &&
      merchantsBySalesChannel
    ) {
      return merchantsBySalesChannel
        .find(
          ({ flywheelSalesChannel }) =>
            flywheelSalesChannel === selectedFlywheelChannel
        )
        ?.merchants.filter(({ id }) => selectedMerchantCountries.includes(id))
        .some(
          ({ productConnectionStatus }) =>
            productConnectionStatus === ConnectionStatus.Active
        );
    }

    return false;
  }, [
    selectedSalesChannelId,
    merchantsBySalesChannel,
    selectedMerchantCountries,
  ]);

  const CAMPAIGN_REQUEST = {
    ...commonRequestFields,
    campaignDetailsFields: [
      ...AD_LEVEL_EXTERNAL_ID_MAPPER.campaigns,
      ...CAMPAIGN_COLUMNS.filter(
        (column) =>
          CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
          CampaignDataApiColumnGroupIdentifier.CampaignDetailsFields
      ),
      ...mandatoryCampaignColumnsCampaignDetails.filter(
        (column) => !apiColumnsWithAdLevel.columns.includes(column)
      ),
    ],
    channelSettingsFields: [
      ...CAMPAIGN_COLUMNS.filter(
        (column) =>
          CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
          CampaignDataApiColumnGroupIdentifier.ChannelSettingsFields
      ),
      ...mandatoryCampaignColumnsChannelSettings.filter(
        (column) => !apiColumnsWithAdLevel.columns.includes(column)
      ),
    ],
    performanceFields: CAMPAIGN_COLUMNS.filter(
      (column) =>
        CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        CampaignDataApiColumnGroupIdentifier.PerformanceFields
    ),
    placementSettingsFields: CAMPAIGN_COLUMNS.filter(
      (column) =>
        CAMPAIGNS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        CampaignDataApiColumnGroupIdentifier.PlacementSettingsFields
    ),
    flywheelSettingsFields: getFlywheelSettingsColumn(
      adLevelDropDownSelectedValues,
      isAIEnabledForOneOfTheSelectedMerchants
    ),
  };

  const mandatoryAdItemDetailColumns =
    selectedSalesChannelName && adTypeDropDownValue
      ? MANDATORY_AD_ITEMS_COLUMNS_AD_ITEM_DETAILS[
          selectedSalesChannelName as FlywheelSalesChannel
        ][adTypeDropDownValue]
      : [];

  const mandatoryTargetsColumnsChannelSettings =
    selectedSalesChannelName && adTypeDropDownValue
      ? MANDATORY_CHANNEL_SETTINGS_TARGETS_COLUMNS[
          selectedSalesChannelName as FlywheelSalesChannel
        ][adTypeDropDownValue]
      : [];

  const AD_ITEMS_COLUMNS = currentTabCheckboxData
    ? [
        ...getAllApiColumns(currentTabCheckboxData, AdLevel.ProductAds),
        PRODUCT_ADS_API_COLUMN_NAME.ProductName,
        PRODUCT_ADS_API_COLUMN_NAME.ProductImage,
      ]
    : [];

  const adItemDetailsFields = [
    ...AD_LEVEL_EXTERNAL_ID_MAPPER.productAds,
    ...AD_ITEMS_COLUMNS.filter(
      (column) =>
        AD_ITEMS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProductAdsApiColumnGroupIdentifier.AdItemDetailsFields
    ),
    ...mandatoryAdItemDetailColumns.filter(
      (column) => !AD_ITEMS_COLUMNS.includes(column)
    ),
  ];

  apiColumnsWithAdLevel.columns.includes(
    PRODUCT_ADS_API_COLUMN_NAME.AdGroupName
  ) && adItemDetailsFields.push(ADLEVEL_DETAILS_FILTER_FIELD.adGroupsId);

  apiColumnsWithAdLevel.columns.includes(
    PRODUCT_ADS_API_COLUMN_NAME.CampaignName
  ) && adItemDetailsFields.push(ADLEVEL_DETAILS_FILTER_FIELD.campaignsId);

  const AD_ITEM_REQUEST = {
    ...commonRequestFields,
    adItemDetailsFields,
    channelSettingsFields: AD_ITEMS_COLUMNS.filter(
      (column) =>
        AD_ITEMS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProductAdsApiColumnGroupIdentifier.ChannelSettingsFields
    ),
    performanceFields: AD_ITEMS_COLUMNS.filter(
      (column) =>
        AD_ITEMS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProductAdsApiColumnGroupIdentifier.PerformanceFields
    ),
  };

  const getAdGroupMandatoryAdgroupDetailsField = (
    selectedSalesChannel: FlywheelSalesChannel
  ) => {
    const adGroupMandatoryAdgroupDetailsField = [
      ADGROUPS_API_COLUMN_NAME.CampaignStatus,
      ADGROUPS_API_COLUMN_NAME.CampaignTargetingType,
      ADGROUPS_API_COLUMN_NAME.CampaignAdFormat,
      ADGROUPS_API_COLUMN_NAME.StartDate,
    ];

    switch (selectedSalesChannel) {
      case FlywheelSalesChannel.Walmart:
        return adGroupMandatoryAdgroupDetailsField;

      case FlywheelSalesChannel.Amazon:
        return [
          ADGROUPS_API_COLUMN_NAME.CampaignCostType,
          ...adGroupMandatoryAdgroupDetailsField,
        ];
    }
  };

  const ADGROUP_COLUMNS: string[] = [
    ADGROUPS_API_COLUMN_NAME.AdGroupName,
    ...(currentTabCheckboxData
      ? getAllApiColumns(currentTabCheckboxData, AdLevel.AdGroups)
      : []),
  ];

  const adGroupDetailsFields = [
    ...AD_LEVEL_EXTERNAL_ID_MAPPER.adGroups,
    ...ADGROUP_COLUMNS.filter(
      (column) =>
        ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        AdgroupDataApiColumnGroupIdentifier.AdGroupDetailsFields
    ),
    ...getAdGroupMandatoryAdgroupDetailsField(
      currentFlywheelSalesChannel
    ).filter((column) => !ADGROUP_COLUMNS.includes(column)),
  ];

  !adGroupDetailsFields.includes(ADGROUPS_API_COLUMN_NAME.MerchantName) &&
    adGroupDetailsFields.push(ADGROUPS_API_COLUMN_NAME.MerchantName);

  ADGROUP_COLUMNS.includes(ADGROUPS_API_COLUMN_NAME.CampaignName) &&
    adGroupDetailsFields.push(ADLEVEL_DETAILS_FILTER_FIELD.campaignsId);

  if (
    isCampaignDailyBudgetColumnMandatoryForAdGroups(
      currentFlywheelSalesChannel,
      apiColumnsWithAdLevel.columns,
      adTypeDropDownValue
    ) &&
    !ADGROUP_COLUMNS.includes(ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget)
  ) {
    adGroupDetailsFields.push(ADGROUPS_API_COLUMN_NAME.CampaignDailyBudget);
  }

  if (
    currentFlywheelSalesChannel === FlywheelSalesChannel.Amazon &&
    adTypeDropDownValue === AdType.SponsoredProducts
  ) {
    adGroupDetailsFields.push(ADGROUPS_API_COLUMN_NAME.IsBiddable);
  }

  adGroupDetailsFields.includes(
    ADGROUPS_API_COLUMN_NAME.AiRecommendationKeywordSetting
  ) && adGroupDetailsFields.push(ADGROUPS_API_COLUMN_NAME.AdGroupUseCase);

  const ADGROUP_REQUEST = {
    ...commonRequestFields,
    adGroupDetailsFields,
    channelSettingsFields: ADGROUP_COLUMNS.filter(
      (column) =>
        ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        AdgroupDataApiColumnGroupIdentifier.ChannelSettingsFields
    ),
    flywheelSettingsFields: getFlywheelSettingsColumn(
      adLevelDropDownSelectedValues,
      isAIEnabledForOneOfTheSelectedMerchants,
      shouldShowSmartCampaigns(optimizelyContext),
      optimizelyContext.featureFlags[OptimizelyFlags.SmartAdGroupsBidOverrides]
    ),
    performanceFields: ADGROUP_COLUMNS.filter(
      (column) =>
        ADGROUP_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        AdgroupDataApiColumnGroupIdentifier.PerformanceFields
    ),
  };

  const PROFILES_COLUMNS: string[] = [
    PROFILES_API_COLUMN_NAME.Brand,
    PROFILES_API_COLUMN_NAME.CampaignId,
    PROFILES_API_COLUMN_NAME.AdGroupId,
    ...(currentTabCheckboxData
      ? getAllApiColumns(currentTabCheckboxData, AdLevel.Profiles)
      : []),
  ];

  const PROFILES_REQUEST: ProfileApiDataRequest = {
    ...commonRequestFields,
    profileDetailsFields: PROFILES_COLUMNS.filter(
      (column) =>
        PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProfileDataApiColumnGroupIdentifier.ProfileDetailsFields
    ),
    channelSettingsFields: PROFILES_COLUMNS.filter(
      (column) =>
        PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProfileDataApiColumnGroupIdentifier.ChannelSettingsFields
    ),
    performanceFields: PROFILES_COLUMNS.filter(
      (column) =>
        PROFILE_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        ProfileDataApiColumnGroupIdentifier.PerformanceFields
    ),
  };

  const TARGETS_COLUMNS = adLevelDropDownSelectedValues
    ? [
        ...(currentTabCheckboxData
          ? getAllApiColumns(
              currentTabCheckboxData,
              adLevelDropDownSelectedValues
            )
          : []),
        ...FLYWHEEL_SALES_CHANNEL_TO_DEFAULT_AD_LEVEL_COLUMNS_MAPPER[
          currentFlywheelSalesChannel
        ][adLevelDropDownSelectedValues],
      ]
    : [];

  const targetDetailsFields = [
    ...(adTypeDropDownValue === AdType.SponsoredDisplay
      ? AD_LEVEL_EXTERNAL_ID_MAPPER.targets
      : AD_LEVEL_EXTERNAL_ID_MAPPER.keywordTargets),
    ...TARGETS_COLUMNS.filter(
      (column) =>
        TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        TargetsDataApiColumnGroupIdentifier.TargetsDetailsFields
    ).map((column) =>
      column === TARGETS_API_COLUMN_NAME.Keyword
        ? TARGETS_API_COLUMN_NAME.TargetText
        : column
    ),
  ];

  // If ad group name is selected, also send adgroup id in request so that we can click on adgroup name in table
  apiColumnsWithAdLevel.columns.includes(TARGETS_API_COLUMN_NAME.AdGroupName) &&
    targetDetailsFields.push(ADLEVEL_DETAILS_FILTER_FIELD.adGroupsId);

  // If campaign name is selected, also send campaign id in request so that we can click on campaign name in table
  apiColumnsWithAdLevel.columns.includes(
    TARGETS_API_COLUMN_NAME.CampaignName
  ) && targetDetailsFields.push(ADLEVEL_DETAILS_FILTER_FIELD.campaignsId);

  const TARGET_REQUEST = {
    ...commonRequestFields,
    targetDetailsFields:
      selectedFlywheelChannel &&
      MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS[selectedFlywheelChannel][
        adLevelDropDownSelectedValues as AdLevel
      ] &&
      MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS[selectedFlywheelChannel][
        adLevelDropDownSelectedValues as AdLevel
      ].length > 0
        ? [
            ...MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS[
              selectedFlywheelChannel
            ][adLevelDropDownSelectedValues as AdLevel],
            ...targetDetailsFields.filter(
              (field) =>
                ![
                  ...AD_LEVEL_API_COLUMNS_TO_EXCLUDE[selectedFlywheelChannel][
                    adLevelDropDownSelectedValues as AdLevel
                  ],
                  ...MANDATORY_TARGETS_COLUMNS_TARGET_DETAILS[
                    selectedFlywheelChannel
                  ][adLevelDropDownSelectedValues as AdLevel],
                ].includes(field)
            ),
          ]
        : targetDetailsFields,
    channelSettingsFields: [
      ...TARGETS_COLUMNS.filter(
        (column) =>
          TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
          TargetsDataApiColumnGroupIdentifier.ChannelSettingsFields
      ),
      ...mandatoryTargetsColumnsChannelSettings.filter(
        (column) => !apiColumnsWithAdLevel.columns.includes(column)
      ),
    ],
    performanceFields: TARGETS_COLUMNS.filter(
      (column) =>
        TARGETS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        TargetsDataApiColumnGroupIdentifier.PerformanceFields
    ),
    targetType: targetTypeRequestParameter,
  };

  const SB_ADS_COLUMNS = adLevelDropDownSelectedValues
    ? [
        ...(currentTabCheckboxData
          ? getAllApiColumns(
              currentTabCheckboxData,
              adLevelDropDownSelectedValues
            )
          : []),
      ]
    : [];

  const creativeDetailsFields = SB_ADS_COLUMNS.filter(
    (column) =>
      SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
      SbAdsApiColumnGroupIdentifier.CreativeDetailsFields
  );

  const SB_ADS_REQUEST = {
    ...commonRequestFields,
    creativeDetailsFields: creativeDetailsFields,
    channelSettingsFields: SB_ADS_COLUMNS.filter(
      (column) =>
        SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        SbAdsApiColumnGroupIdentifier.ChannelSettingsFields
    ),
    performanceFields: SB_ADS_COLUMNS.filter(
      (column) =>
        SB_ADS_API_COLUMN_TO_COLUMN_GROUP_IDENTIFIER[column] ===
        SbAdsApiColumnGroupIdentifier.PerformanceFields
    ),
  };

  const createAdditionalQuickFilterForApi = (
    campaignTypeQuickFilter: CampaignTypeQuickFilterKey
  ): Filter[] => {
    return campaignTypeQuickFilter !== CampaignTypeQuickFilterKey.All
      ? [
          inFilter(
            CAMPAIGNS_API_COLUMN_NAME.AddedBy,
            campaignTypeQuickFilter === CampaignTypeQuickFilterKey.External
              ? [AddedBy.External]
              : [AddedBy.CampaignCreatorV1, AddedBy.FlywheelAutomated]
          ),
        ]
      : [];
  };

  const [
    campaignTypeQuickFilterCampaignTab,
    setCampaignTypeQuickFilterCampaignTab,
  ] = useState<CampaignTypeQuickFilterKey>(CampaignTypeQuickFilterKey.All);

  const loadHeroMetricsAfterQuickFilterUpdate = (quickFilter: string) => {
    const filters: Filter[] = [
      ...currentTableFilters,
      ...createAdditionalQuickFilterForApi(
        quickFilter as CampaignTypeQuickFilterKey
      ),
    ];
    loadCurrentHeroMetrics(filters);
    loadPreviousHeroMetrics(filters);
  };

  const onQuickFilterClickCampaignTab = (key: string) => {
    setCampaignTypeQuickFilterCampaignTab(key as CampaignTypeQuickFilterKey);
    loadHeroMetricsAfterQuickFilterUpdate(key);
    dispatch(
      tableActions.setVisiblePage({
        page: 1,
        tableId: ADS_MANAGER_CAMPAIGNS_TABLE_ID,
      })
    );
  };

  const [quickFilterCountsCampaignsTab, setQuickFilterCountsCampaignsTab] =
    useState<PaginatedResponseExtraData>();

  const onQuickFilterUpdateCampaign = (extraData: PaginatedResponseExtraData) =>
    setQuickFilterCountsCampaignsTab(extraData);

  const CAMPAIGN_DATA_FETCHER = aoApiClient.getAdLevelData<
    CampaignDataRequest,
    CampaignDetails
  >(
    accountId,
    AdLevelApiEndpoint.Campaigns,
    CAMPAIGN_REQUEST,
    createAdditionalQuickFilterForApi(campaignTypeQuickFilterCampaignTab),
    ALL_AD_LEVELS_FILTER_FILED_MAPPER,
    getTransformedAddedByFilter
  );

  const CAMPAIGN_DATA_FETCHER_WITHOUT_QUICK_FILTER = aoApiClient.getAdLevelData<
    CampaignDataRequest,
    CampaignDetails
  >(
    accountId,
    AdLevelApiEndpoint.Campaigns,
    CAMPAIGN_REQUEST,
    [],
    ALL_AD_LEVELS_FILTER_FILED_MAPPER,
    getTransformedAddedByFilter
  );

  const campaignDetailFilters = adsManagerDetailsPageFlag
    ? [equalToFilter(ADLEVEL_DETAILS_FILTER_FIELD.campaignsId, adLevelId)]
    : [];

  const adGroupDetailFilters = adsManagerDetailsPageFlag
    ? [equalToFilter(ADLEVEL_DETAILS_FILTER_FIELD.adGroupsId, adLevelId)]
    : [];

  const SB_ADS_DATA_FETCHER = aoApiClient.getAdLevelData<
    SbAdsDataRequest,
    SbAdsDetails
  >(
    accountId,
    AdLevelApiEndpoint.SbAds,
    SB_ADS_REQUEST,
    campaignDetailFilters, // SB Campaign details page support
    ALL_AD_LEVELS_FILTER_FILED_MAPPER
  );

  const [
    campaignTypeQuickFilterAdGroupTab,
    setCampaignTypeQuickFilterAdGroupTab,
  ] = useState<CampaignTypeQuickFilterKey>(CampaignTypeQuickFilterKey.All);

  const [quickFilterCountsAdGroupTab, setQuickFilterCountsAdGroupTab] =
    useState<PaginatedResponseExtraData>();

  const onQuickFilterClickAdGroupTab = (key: string) => {
    setCampaignTypeQuickFilterAdGroupTab(key as CampaignTypeQuickFilterKey);
    loadHeroMetricsAfterQuickFilterUpdate(key);
    dispatch(
      tableActions.setVisiblePage({
        page: 1,
        tableId: ADS_MANAGER_ADGROUP_TABLE_ID,
      })
    );
  };

  const onQuickFilterUpdateAdGroup = (extraData: PaginatedResponseExtraData) =>
    setQuickFilterCountsAdGroupTab(extraData);

  const ADGROUP_DATA_FETCHER = aoApiClient.getAdLevelData<
    AdGroupDataRequest,
    AdGroupDetails
  >(
    accountId,
    AdLevelApiEndpoint.Adgroups,
    ADGROUP_REQUEST,
    [
      ...campaignDetailFilters,
      ...createAdditionalQuickFilterForApi(campaignTypeQuickFilterAdGroupTab),
    ],
    ALL_AD_LEVELS_FILTER_FILED_MAPPER,
    getTransformedAddedByFilter
  );

  const ADGROUP_DATA_FETCHER_WITHOUT_QUICK_FILTER = aoApiClient.getAdLevelData<
    AdGroupDataRequest,
    AdGroupDetails
  >(
    accountId,
    AdLevelApiEndpoint.Adgroups,
    ADGROUP_REQUEST,
    campaignDetailFilters,
    ALL_AD_LEVELS_FILTER_FILED_MAPPER,
    getTransformedAddedByFilter
  );

  const PROFILES_DATA_FETCHER = aoApiClient.getAdLevelData<
    ProfileApiDataRequest,
    ProfileApiDataDetails
  >(
    accountId,
    AdLevelApiEndpoint.Profiles,
    PROFILES_REQUEST,
    adGroupDetailFilters, // we can only come to profiles page using adgroup, that's why adgroup filters
    ALL_AD_LEVELS_FILTER_FILED_MAPPER
  );

  const AD_ITEM_DATA_FETCHER = aoApiClient.getAdLevelData<
    ProductAdsDataRequest,
    ProductAdDetails
  >(
    accountId,
    AdLevelApiEndpoint.ProductAds,
    AD_ITEM_REQUEST,
    adGroupDetailFilters,
    ALL_AD_LEVELS_FILTER_FILED_MAPPER,
    transFormAdItemsFilters
  );

  const targetDataAdditionalFilters = () => {
    if (
      adTypeDropDownValue === AdType.SponsoredBrands &&
      identifiedAdLevel === AdLevel.Campaigns
    ) {
      return campaignDetailFilters;
    } else if (
      adTypeDropDownValue === AdType.SponsoredProducts &&
      identifiedAdLevel === AdLevel.Campaigns &&
      adsManagerDetailsPageFlag
    ) {
      return [
        ...campaignDetailFilters,
        inFilter(ADLEVEL_DETAILS_FILTER_FIELD.entityType, [
          SP_CAMPAIGN_NEGATIVE_KEYWORD,
          SP_NEGATIVE_KEYWORD,
        ]),
      ];
    }
    return adGroupDetailFilters;
  };

  const detailsPageFiltersForTargets = targetDataAdditionalFilters();

  const TARGET_DATA_FETCHER = aoApiClient.getAdLevelData<
    TargetsDataRequest,
    TargetsDetails
  >(
    accountId,
    adTypeDropDownValue === AdType.SponsoredDisplay
      ? AdLevelApiEndpoint.Targets
      : AdLevelApiEndpoint.KeywordTargets,
    TARGET_REQUEST,
    targetDataAdditionalFilters(),
    ALL_AD_LEVELS_FILTER_FILED_MAPPER,
    getTargetingExpressionAndTargetLabelFilters(intl, [
      TARGETS_TABLE_SEARCH_TARGETING_EXPRESSION,
    ])
  );

  const isCurrentAdLevelAndPageUrlAdLevelSame = () => {
    let currentPathName = location.pathname || '';
    const splittedCurrentPathName = currentPathName.split('');

    if (last(splittedCurrentPathName) === '/') {
      currentPathName = currentPathName.slice(0, -1);
    }
    return (
      adLevelDropDownSelectedValues &&
      AD_LEVEL_TO_ADS_MANAGER_PAGE_MAPPER[adLevelDropDownSelectedValues].filter(
        (page) => page === currentPathName
      ).length > 0
    );
  };

  const isCurrentAdLevelAndDetailsPageAdLevelSame = () => {
    return (
      adsManagerDetailsPageFlag &&
      adLevelDropDownSelectedValues &&
      ADS_MANAGER_DETAILS_PAGE_AD_LEVEL_MAPPER[identifiedAdLevel].includes(
        adLevelDropDownSelectedValues
      )
    );
  };

  /*
    Display table only if
    1. Data inspection request is complete.
    2. Merchant countries are available.
    3. There is no intermediate ad level state.
    4. Ad level value in dropdown and URL is same.
  */
  const shouldDisplayAdLevelTable =
    adTypeDropDownValue &&
    asyncRequestIsComplete(dataInspectionRequest) &&
    selectedMerchantCountries.length > 0 &&
    apiColumnsWithAdLevel.adLevel === adLevelDropDownSelectedValues &&
    (adsManagerDetailsPageFlag
      ? isCurrentAdLevelAndDetailsPageAdLevelSame()
      : isCurrentAdLevelAndPageUrlAdLevelSame());

  const navigate = useNavigate();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const tableChanges: TableChange = useSelector<
    WithTable<CampaignDetails>,
    TableChange
  >(({ tableState }) =>
    tableSelectors.getChangeSelector()(tableState, currentTableId)
  );

  useEffect(() => {
    const currentPageInSession = getCurrentPageFromSessionStorage(
      userId,
      accountId
    );

    if (!isNil(currentPageInSession)) {
      dispatch(
        tableActions.setVisiblePage({
          tableId: currentTableId,
          page: Number(currentPageInSession),
        })
      );
    }
  }, [dispatch, currentTableId]);

  useEffect(() => {
    return storeCurrentPageToSessionStorage(userId, accountId, 1);
  }, [location.pathname]);

  useEffect(() => {
    if (asyncRequestIsFailed(heroMetricsCurrentMetricRequest)) {
      setHeroMetricsLoadAttempts((previousCount) => previousCount + 1);
    }
    if (asyncRequestIsComplete(heroMetricsCurrentMetricRequest)) {
      setHeroMetricsLoadAttempts(0);
    }
  }, [heroMetricsCurrentMetricRequest]);

  useEffect(() => {
    const flattenDataInspectorColumns: Checkbox = {};
    Object.keys(tabCheckboxMetadata || {}).forEach((key) => {
      if (tabCheckboxMetadata?.[key]) {
        merge(flattenDataInspectorColumns, tabCheckboxMetadata[key]);
      }
    });
    const currentColumns = Object.keys(flattenDataInspectorColumns).filter(
      (key) => flattenDataInspectorColumns[key].isSelected
    );
    setSelectedColumns(currentColumns);
  }, [tabCheckboxMetadata]);

  const retryHeroMetricsLoad = () => {
    loadCurrentHeroMetrics([...currentTableFilters]);
    loadPreviousHeroMetrics([...currentTableFilters]);
  };

  const openIntercomChat = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    talkToUs();
  };

  const saveChanges = async (savedTableId: string) => {
    setIsSaveChangesLoading(true);
    let isSaveSuccess = false;
    switch (savedTableId) {
      case ADS_MANAGER_CAMPAIGNS_TABLE_ID:
        isSaveSuccess = await handleCampaignSave();
        break;

      case ADS_MANAGER_ADGROUP_TABLE_ID:
        isSaveSuccess = await handleAdGroupSave();
        break;
      case ADS_MANAGER_SV_ADS_TABLE_ID:
      case ADS_MANAGER_AD_ITEMS_TABLE_ID:
        isSaveSuccess = await handleProductAdSave();
        break;

      case ADS_MANAGER_TARGETS_TABLE_ID:
        isSaveSuccess = await handleTargetSave();
        break;

      case ADS_MANAGER_PROFILES_TABLE_ID:
        isSaveSuccess = await handleProfileSave();
        break;
    }
    dispatch(
      tableActions.clearAllChanges({
        tableId: savedTableId,
      })
    );
    setIsSaveChangesLoading(false);
    showSaveResultMessage(isSaveSuccess);
  };

  const checkAndHandleGroupsSave = async () => {
    try {
      const currentlySelectedMerchantCountryId = isArray(
        selectedMerchantCountries
      )
        ? first(selectedMerchantCountries as string[])
        : selectedMerchantCountries;

      const assignGroupsRequest: CampaignGroupAssignRequest =
        getUpdateGroupsPayload(
          tableChanges,
          campaignTableData,
          getAllGroupsInfoForSelectedMerchantCountry(),
          currentlySelectedMerchantCountryId!
        );

      await aoApiClient.assignCampaignGroups(accountId, assignGroupsRequest);
    } catch {
      toasts.addNotification({
        headline: intl.formatMessage({ id: I18nKey.FAILED }),
        description: intl.formatMessage({
          id: I18nKey.AD_OPTIMIZER_TABLE_GROUP_SAVE_FAILED,
        }),
        type: Type.Attention,
        dataTestId: 'ao_adsManager_groupsFailed',
      });
    }
  };

  const handleCampaignSave = async (): Promise<boolean> => {
    const groupChangeRequired = Object.keys(tableChanges.cell).find(
      (key) => tableChanges.cell[key].groupLabel
    );

    if (groupChangeRequired) {
      await checkAndHandleGroupsSave();
    }

    const updateCampaignChannelSettingsRequest: UpdateCampaignChannelSettingsRequest =
      getUpdateCampaignChannelSettingsRequest(
        tableChanges,
        selectedMerchantCountryCode,
        selectedSalesChannelId,
        portfolios,
        currentFlywheelSalesChannel
      );

    const isChannelSettingSaveSuccess =
      await aoApiClient.updateCampaignChannelSettings(
        accountId,
        updateCampaignChannelSettingsRequest
      );
    let isSaveSuccess = isChannelSettingSaveSuccess;
    if (currentFlywheelSalesChannel === FlywheelSalesChannel.Amazon) {
      const updateFlywheelSettingsRequest: UpdateFlywheelSettingsRequest =
        getUpdateFlywheelSettingsRequestForCampaigns(
          tableChanges,
          campaignTableData,
          adLevelDropDownSelectedValues as AdLevel,
          selectedMerchantCountryCode,
          selectedFlywheelChannel,
          isArray(selectedMerchantCountries)
            ? first(selectedMerchantCountries as string[])
            : selectedMerchantCountries
        );
      const hasFlyWheelSettingsDataChanged: boolean =
        updateFlywheelSettingsRequest.creates.length > 0 ||
        updateFlywheelSettingsRequest.updates.length > 0;
      const isFlywheelUpdateSuccess: boolean = hasFlyWheelSettingsDataChanged
        ? await aoApiClient.updateFlywheelSettings(
            accountId,
            updateFlywheelSettingsRequest
          )
        : false;
      isSaveSuccess = hasFlyWheelSettingsDataChanged
        ? isSaveSuccess && isFlywheelUpdateSuccess
        : isSaveSuccess;
    }

    dispatch(tableThunks.refreshTable(currentTableId, CAMPAIGN_DATA_FETCHER));

    return isSaveSuccess;
  };

  const handleAdGroupSave = async (): Promise<boolean> => {
    const updateAdgroupsChannelSettingsRequest: UpdateAdgroupChannelSettingsRequest =
      getUpdateAdgroupsChannelSettingsRequest(
        tableChanges,
        selectedMerchantCountryCode,
        selectedMerchantCountryInfo?.merchantCountryId,
        selectedSalesChannelId
      );

    const hasChannelSettingsDataChanged: boolean =
      updateAdgroupsChannelSettingsRequest.updates.length > 0;

    const isChannelSettingSaveSuccess: boolean = hasChannelSettingsDataChanged
      ? await aoApiClient.updateAdGroupChannelSettings(
          accountId,
          updateAdgroupsChannelSettingsRequest
        )
      : false;

    const updateFlywheelSettingsRequest: UpdateFlywheelSettingsRequest =
      getUpdateFlywheelSettingsRequest(
        tableChanges,
        adGrouptableData,
        adLevelDropDownSelectedValues as AdLevel,
        selectedMerchantCountryCode,
        selectedFlywheelChannel,
        constraints,
        adTypeDropDownValue as AdType,
        currentFlywheelSalesChannel,
        shouldShowSmartCampaigns(optimizelyContext),
        selectedMerchantType,
        isArray(selectedMerchantCountries)
          ? first(selectedMerchantCountries as string[])
          : selectedMerchantCountries
      );

    const hasFlyWheelSettingsDataChanged: boolean =
      updateFlywheelSettingsRequest.creates.length > 0 ||
      updateFlywheelSettingsRequest.updates.length > 0;
    const isFlywheelUpdateSuccess: boolean = hasFlyWheelSettingsDataChanged
      ? await aoApiClient.updateFlywheelSettings(
          accountId,
          updateFlywheelSettingsRequest
        )
      : false;

    const isSaveSuccessForChannelSettings = hasChannelSettingsDataChanged
      ? isChannelSettingSaveSuccess
      : true;
    const isSaveSuccessForFlywheelSettings = hasFlyWheelSettingsDataChanged
      ? isFlywheelUpdateSuccess
      : true;

    const isSaveSuccess =
      isSaveSuccessForChannelSettings && isSaveSuccessForFlywheelSettings;

    dispatch(tableThunks.refreshTable(currentTableId, ADGROUP_DATA_FETCHER));

    return isSaveSuccess;
  };

  const handleProductAdSave = async (): Promise<boolean> => {
    const productAdsUpdatedCells =
      tableChanges.cell as TableCellChangeProductAd;
    const currencyCode = tableChanges.global.currencyCode;
    const updateProductAdsChannelSettingsRequest: EditProductAdRequest =
      getEditProductAdRequest(
        productAdsUpdatedCells,
        currencyCode,
        getSelectedMerchantCountryId(selectedMerchantCountries) ?? ''
      );
    const isSaveSuccess = await aoApiClient.updateProductChannelSettings(
      accountId,
      updateProductAdsChannelSettingsRequest
    );
    dispatch(tableThunks.refreshTable(currentTableId, AD_ITEM_DATA_FETCHER));

    return isSaveSuccess;
  };

  const handleProfileSave = async (): Promise<boolean> => {
    const profileUpdatedCells = tableChanges.cell as TableCellChangeProfile;
    const updateProfilesRequest: EditProfileRequest =
      getEditProfileRequest(profileUpdatedCells);
    const isSaveSuccess = await aoApiClient.updateProfileChannelSettings(
      accountId,
      updateProfilesRequest
    );
    dispatch(tableThunks.refreshTable(currentTableId, AD_ITEM_DATA_FETCHER));
    return isSaveSuccess;
  };

  const handleTargetSave = async (): Promise<boolean> => {
    const targetUpdatedCells = tableChanges.cell as TableCellChangeTarget;
    const currencyCode = tableChanges.global.currencyCode;
    const updateTargetChannelSettingsRequest: EditTargetsRequest =
      getEditTargetRequest(
        targetUpdatedCells,
        currencyCode,
        selectedMerchantCountryInfo?.merchantCountryId
      );

    const isSaveSuccess = await aoApiClient.updateTargetsChannelSettings(
      accountId,
      updateTargetChannelSettingsRequest
    );
    dispatch(tableThunks.refreshTable(currentTableId, TARGET_DATA_FETCHER));

    return isSaveSuccess;
  };

  const showSaveResultMessage = (isSaveSuccess: boolean) => {
    let headline: string;
    let description: string;
    let type: Type;
    if (isSaveSuccess) {
      headline = intl.formatMessage({ id: I18nKey.SUBMITTED });
      description = intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_REQUEST_PROCESSING,
      });
      type = Type.Success;
    } else {
      headline = intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE_HEADLINE,
      });
      description = intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_TOAST_MESSAGE_SAVE_EDITED_CHANGES_FAILURE,
      });
      type = Type.Attention;
    }
    toasts.addNotification({
      headline: headline,
      description: description,
      type: type,
      dataTestId: 'ao_adsManagerChangesSaved',
    });
  };

  const handleTableSave = () => saveChanges(currentTableId);

  const onFilterUpdate = (filters: Filter[]) => {
    loadCurrentHeroMetrics(filters);
    loadPreviousHeroMetrics(filters);
    setCurrentTableFilters(filters);
  };
  const storeFiltersInBrowserStorage = (filters: Filter[]) => {
    storeFiltersInLocalStorage(
      userId,
      accountId,
      adTypeDropDownValue!,
      adLevelDropDownSelectedValues,
      filters,
      selectedSalesChannelId,
      currentAdLevelId
    );
  };

  const getPortfoliosForSelectedMerchantCountry = () => {
    const currentlySelectedMerchantCountryId = isArray(
      selectedMerchantCountries
    )
      ? first(selectedMerchantCountries as string[])
      : selectedMerchantCountries;

    const filteredPortfolios = portfolios.filter(
      (portfolio) =>
        portfolio.merchantCountryId === currentlySelectedMerchantCountryId
    );

    return [
      {
        name: intl.formatMessage({
          id: I18nKey.ADS_MANAGER_CAMPAIGNS_TABLE_NO_PORTFOLIO_LABEL,
        }),
      },
      ...filteredPortfolios,
    ];
  };

  const getAllGroupsInfoForSelectedMerchantCountry = (): Groups[] => {
    const currentlySelectedMerchantCountryId = isArray(
      selectedMerchantCountries
    )
      ? first(selectedMerchantCountries as string[])
      : selectedMerchantCountries;

    const filteredAllAoGroups = aoGroups.filter(
      (group) => group.merchantCountryId === currentlySelectedMerchantCountryId
    );

    return [
      {
        name: intl.formatMessage({
          id: I18nKey.AD_OPTIMIZER_FLYOVER_EDIT_GROUP_NO_GROUP,
        }),
        groupId: NO_GROUP_VALUE,
      },
      ...filteredAllAoGroups,
    ];
  };

  const selectedMerchantsWithAutomationEnabled = merchantsBySalesChannel
    .find(
      ({ flywheelSalesChannel }) =>
        flywheelSalesChannel === selectedFlywheelChannel
    )
    ?.merchants.filter(
      ({ aiEnabled, id }) =>
        aiEnabled &&
        merchantCountriesForSelectedSalesChannel
          .map(({ merchantCountryId }) => merchantCountryId)
          .includes(id) &&
        selectedMerchantCountries.includes(id)
    )
    .map(({ id }) => id);

  const totalNumberOfMerchantsForSalesChannel =
    merchantsBySalesChannel
      .find(
        ({ flywheelSalesChannel }) =>
          flywheelSalesChannel === selectedFlywheelChannel
      )
      ?.merchants.filter(
        ({ id }) =>
          merchantCountriesForSelectedSalesChannel
            .map(({ merchantCountryId }) => merchantCountryId)
            .includes(id) && selectedMerchantCountries.includes(id)
      )?.length ?? 0;

  const numberOfMerchantWithAIPlanEnabled =
    selectedMerchantsWithAutomationEnabled?.length ?? 0;

  const afterBillingPlanUpgrade = () => {
    const dataInspectorConfig = enableFwSettingsColumns(
      tabsCheckboxesForAllAdLevels
    );
    updateSubscriptionInformation();
    setTabsCheckboxesForAllAdLevels(dataInspectorConfig);
  };

  const isAIPlan = isAIPlanEnabled(userContext);

  const containsFlywheelSettings =
    getFlywheelSettingsColumn(
      adLevelDropDownSelectedValues,
      true,
      shouldShowSmartCampaigns(optimizelyContext),
      optimizelyContext.featureFlags[OptimizelyFlags.SmartAdGroupsBidOverrides]
    ).length > 0 && adTypeDropDownValue !== AdType.SponsoredVideos;

  const dropdownMetadataOptions =
    (asyncRequestIsComplete(dataInspectionRequest) &&
      dataInspectionRequest.result) ||
    [];

  const getAdLevelsWithAdType = (adTypeValue: string | undefined) => {
    const option = dropdownMetadataOptions.find(
      (elem) => elem.parent.value === adTypeValue
    );

    if (!option) {
      return [];
    }

    return option.child.map((elem) => ({
      name: elem.label,
      value: elem.value,
      dataTestId: `tab-${elem.value}`,
    }));
  };

  const adLevels = useMemo(() => {
    return !isAIPlan || numberOfMerchantWithAIPlanEnabled < 1
      ? getAdLevelsWithAdType(adTypeDropDownValue)
      : getAdLevelsWithAdType(adTypeDropDownValue).map((item) => {
          if (item.value !== AdLevel.AdGroups) {
            return item;
          }

          return {
            ...item,
            aiStatus: getAiHintStatusIfAdgroupOrCampaginSBType(
              adLevelDropDownSelectedValues,
              adTypeDropDownValue,
              optimizelyContext.featureFlags[
                OptimizelyFlags.SponsoredVideosEditable
              ]
            ),
          };
        });
  }, [dropdownMetadataOptions, adTypeDropDownValue]);

  const columnManagerOptions = useMemo(
    () => getColumnManagerOptions(tabCheckboxMetadata || {}),
    [tabCheckboxMetadata]
  );

  const MERCHANT_PICKER_OPTIONS: SelectOptionProps<string>[] = useMemo(
    () =>
      generateMerchantPickerOptions(
        merchantCountriesContext?.merchantCountries || [],
        subscribedSalesChannels,
        intl
      ),
    [merchantCountriesContext?.merchantCountries, subscribedSalesChannels, intl]
  );

  const cogsDetailsDataFetcher = (entityId: string, entityType: EntityType) => {
    const currentMcId = isArray(selectedMerchantCountries)
      ? first(selectedMerchantCountries as string[])
      : selectedMerchantCountries;

    return aoApiClient.getCogsDetailsForAdLevel(accountId, {
      merchantCountryId: currentMcId || '',
      salesChannelId: selectedSalesChannelId,
      merchantType: selectedMerchantType || MerchantType.Seller,
      entityId: entityId,
      entityType: entityType.toUpperCase(),
    });
  };

  const { updatePageHeaderData } =
    useContext<PageHeaderContextState>(PageHeaderContext);

  useEffect(() => {
    updatePageHeaderData({
      title: (
        <div className="flex items-center gap-8 font-normal">
          <span className="font-semibold">{pageTitle}</span>
        </div>
      ),
      titleString: intl.formatMessage({
        id: I18nKey.ADVERTISING_OPTIMIZATION_TITLE,
      }),
      dataTestId: 'ao_adsManagerUserDetails',
      breadCrumbs,
    });
  }, [breadCrumbs]);

  const merchantsSyncing = useMerchantsSyncing();
  const pageData = useEmptyStateData(
    PageType.AdsManager,
    merchantsSyncing.isProductConnected,
    merchantsSyncing.isAdsConnected
  );

  const { loadConstraints, constraints } = useBidConstraintsContext();

  useEffect(() => {
    if (!isChatGPTUrl) loadConstraints();
  }, [pageData.isVisible, isChatGPTUrl]);

  if (!merchantsSyncing.isMerchantSyncLoaded) {
    return <Spinner />;
  }

  if (!pageData.isVisible) {
    return (
      <EmptyState
        title={intl.formatMessage({
          id: I18nKey.ADVERTISING_OPTIMIZATION_TITLE,
        })}
        isInSync={merchantsSyncing.isInSync}
        pageType={PageType.AdsManager}
        merchantsSyncing={merchantsSyncing}
      />
    );
  }

  return (
    <CreateChatGPTKeywordsProvider
      salesChannelId={selectedSalesChannelId}
      merchantCountryId={getMerchantCountryId(selectedMerchantCountries)}
      merchantType={selectedMerchantType!}
      adType={adTypeDropDownValue as AdType}
    >
      <>
        {isChatGPTUrl && (
          <Routes>
            {Object.values(ChatGPTEmbeddedURLs)?.map((path) => {
              return (
                <Route path={path} element={<CreateChatGPTKeywordModal />} />
              );
            })}
          </Routes>
        )}
        {!isChatGPTUrl && (
          <Layout classNames="xl:max-w-screen-2xl xl:mx-auto my-0">
            {showTrendsSlideover && selectedTrendsProduct && (
              <PerformanceSlideoverContextProvider
                currency={
                  getCurrencyCodeFromMerchantCountryCode(
                    selectedMerchantCountryCode
                  ) as CurrencyCode
                }
                defaultDateRange={{
                  startDate: selectedDateRange.initialStartDate,
                  endDate: selectedDateRange.initialEndDate,
                }}
                {...selectedTrendsProduct}
              >
                <PerformanceSlideover onClose={onViewTrendsSlideoverClose} />
              </PerformanceSlideoverContextProvider>
            )}
            {displayConnectionsBanner ===
              AOConnectionBanner.ConnectionSuccessful && (
              <>
                <ContextSetterV3
                  primaryDropdownProps={{
                    primaryDropdownLabel: intl.formatMessage({
                      id: I18nKey.CONTEXT_SETTER_SALES_CHANNEL_PICKER_MERCHANTS,
                    }),
                    primaryDropdownPlaceholder: '',
                    primaryDropdownOptions:
                      MERCHANT_PICKER_OPTIONS as SelectV2OptionProps<
                        string | number
                      >[],
                    primaryDropdownValue: isArray(selectedMerchantCountries)
                      ? selectedMerchantCountries[0]
                      : selectedMerchantCountries,
                    primaryDropdownShowSearch: true,
                    onSelectPrimaryDropdown: handleMerchantChange,
                    primaryDropdownVariant:
                      SelectMenuVariant.TextWithIconHighlight,
                    primaryDropdownState: getDropdownState(
                      adsManagerDetailsPageFlag
                    ),
                  }}
                  secondaryDropdownProps={{
                    secondaryDropdownLabel: intl.formatMessage({
                      id: I18nKey.ADVERTISING_OPTIMIZATION_DATA_INSPECTOR_ANALYZE,
                    }),
                    secondaryDropdownOptions: dropdownMetadataOptions.map(
                      (dropdown) => dropdown.parent
                    ),
                    secondaryDropdownValue: adTypeDropDownValue as string,
                    onSelectSecondaryDropdown: onAdTypeChange,
                    secondaryDropdownShowSearch: false,
                    secondaryDropDownAddValueToDataTestId: true,
                    secondaryDropdownPlaceholder: '',
                    secondaryDropdownState: getDropdownState(
                      adsManagerDetailsPageFlag
                    ),
                  }}
                  timeFrameDropdownProps={{
                    onDateRangeChange: handleDateRangeChange,
                    timeFrameLabel: intl.formatMessage({
                      id: I18nKey.CONTEXT_SETTER_TIMEFRAME_LABEL,
                    }),
                    showCustomCalendarOption: true,
                    initialStartDate: selectedDateRange.initialStartDate,
                    initialEndDate: selectedDateRange.initialEndDate,
                    minDate: selectedDateRange.minDate,
                    maxDate: selectedDateRange.maxDate,
                    dateRangeDataTestId: dateRangeDataTestId,
                    dateRangeDataTestIdForStartDate:
                      dateRangeDataTestIdForStartDate,
                  }}
                  isLoading={dataInspectionRequest === AsyncRequestLoading()}
                  dataTestId="ao_merchantContext"
                />
                <div className="ads-manager-table px-24">
                  <HeroMetricsV2
                    currentMetricRequest={heroMetricsCurrentMetricRequest}
                    previousMetricRequest={heroMetricsPreviousMetricRequest}
                    merchantCountry={selectedMerchantCountryCode}
                    contactSupport={openIntercomChat}
                    retryHeroMetricsLoad={retryHeroMetricsLoad}
                    loadHeroMetricsAttempts={heroMetricsLoadAttempts}
                    earliestDataAvailibilityDate={selectedDateRange.minDate}
                    tableFilters={filtersFromStore}
                    dataTestId={'ao_adsManager'}
                  />
                  <LevelsController
                    levels={adLevels}
                    active={adLevelDropDownSelectedValues}
                    onChange={onLevelControllerChange}
                  />
                  {isAiDisabledForOneOftheSelectedMerchant &&
                    isAIPlan &&
                    selectedFlywheelChannel &&
                    containsFlywheelSettings && (
                      <EnableAIBanner
                        flywheelSalesChannel={selectedFlywheelChannel}
                        totalNumberOfMerchantsForSalesChannel={
                          totalNumberOfMerchantsForSalesChannel
                        }
                        numberOfMerchantWithAIPlanEnabled={
                          numberOfMerchantWithAIPlanEnabled
                        }
                        updateSubscription={updateSubscriptionInformation}
                      />
                    )}

                  {!isAIPlan && containsFlywheelSettings && (
                    <UpgradePlanWrapper
                      famApiClient={famApiClient}
                      afterPlanUpgrade={afterBillingPlanUpgrade}
                      updateMerchantsInfoAfterUpdate={() =>
                        updateMerchantsBySalesChannelOnAIPlanEnable(true)
                      }
                    />
                  )}

                  {!shouldDisplayAdLevelTable && <Loader.default />}

                  {shouldDisplayAdLevelTable && (
                    <Routes>
                      {[
                        ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsCampaigns,
                        ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsCampaigns,
                        ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayCampaigns,
                        ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsCampaigns,
                        ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierCampaigns,
                        ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosCampaigns,
                      ].map((path, index) => (
                        <Route
                          path={path}
                          element={
                            <TableCampaigns
                              isSaveChangesLoading={isSaveChangesLoading}
                              key={path}
                              apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                              tableMode={tableMode}
                              dataFetcher={CAMPAIGN_DATA_FETCHER}
                              toggleTableMode={toggleTableMode}
                              onEditSave={handleTableSave}
                              request={() => CAMPAIGN_REQUEST}
                              aoApiClient={aoApiClient}
                              famApiClient={famApiClient}
                              selectedSalesChannelName={
                                selectedSalesChannelName
                              }
                              exportButtonState={exportButtonState}
                              setExportButtonState={setExportButtonState}
                              adLevelDropDownSelectedValues={
                                adLevelDropDownSelectedValues
                              }
                              selectedSalesChannel={currentFlywheelSalesChannel}
                              selectedAdType={adTypeDropDownValue as AdType}
                              activeSliderIndex={
                                tableMode === DualModes.View ? 0 : 1
                              }
                              onFilterUpdate={onFilterUpdate}
                              storeFiltersInBrowserStorage={
                                storeFiltersInBrowserStorage
                              }
                              adTypes={
                                adTypeDropDownValue ? [adTypeDropDownValue] : []
                              }
                              merchantType={selectedMerchantType}
                              portfolioNames={getPortfoliosForSelectedMerchantCountry()}
                              groupNames={getAllGroupsInfoForSelectedMerchantCountry()}
                              reloadGroupsData={reloadGroupsData}
                              merchantCountry={selectedMerchantCountryCode}
                              startDate={selectedDateRange.initialStartDate}
                              endDate={selectedDateRange.initialEndDate}
                              selectedMerchantCountries={
                                selectedMerchantCountries
                              }
                              allMerchants={
                                merchantCountriesForSelectedSalesChannel
                              }
                              merchantCountryNameForCSVDownload={
                                merchantCountryNameForCSVDownload
                              }
                              aiEnabled={
                                !isAiDisabledForOneOftheSelectedMerchant
                              }
                              merchantsWithAutomationEnabled={
                                selectedMerchantsWithAutomationEnabled ?? []
                              }
                              selectedSalesChannelId={selectedSalesChannelId}
                              columnManagerOptions={columnManagerOptions}
                              selectedColumns={selectedColumns}
                              onColumnSelectionChange={onColumnSelectionChange}
                              switchLevelController={switchLevelController}
                              checkavailability={disCheckavailability}
                              campaignTypeQuickFilter={
                                campaignTypeQuickFilterCampaignTab
                              }
                              onQuickFilterClick={onQuickFilterClickCampaignTab}
                              quickFilterData={quickFilterCountsCampaignsTab}
                              setQuickFilterData={onQuickFilterUpdateCampaign}
                              dataFetcherWithoutQuickFilter={
                                CAMPAIGN_DATA_FETCHER_WITHOUT_QUICK_FILTER
                              }
                              cogsDetailsDataFetcher={cogsDetailsDataFetcher}
                            />
                          }
                        />
                      ))}
                      {adLevelDropDownSelectedValues === AdLevel.AdGroups &&
                        [
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsAdGroups,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsAdGroups,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsAdGroups,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayAdGroups,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierAdGroups,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierCampaignsDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosAdGroups,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosCampaignDetails,
                        ].map((path, index) => (
                          <Route
                            path={path}
                            element={
                              <TableAdGroup
                                isSaveChangesLoading={isSaveChangesLoading}
                                selectedAdLevel={
                                  adLevelDropDownSelectedValues as AdLevel
                                }
                                key={path}
                                apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                                tableMode={tableMode}
                                dataFetcher={ADGROUP_DATA_FETCHER}
                                toggleTableMode={toggleTableMode}
                                request={() => ADGROUP_REQUEST}
                                aoApiClient={aoApiClient}
                                selectedSalesChannelName={
                                  selectedSalesChannelName
                                }
                                exportButtonState={exportButtonState}
                                setExportButtonState={setExportButtonState}
                                adLevelDropDownSelectedValues={
                                  adLevelDropDownSelectedValues
                                }
                                activeSliderIndex={
                                  tableMode === DualModes.View ? 0 : 1
                                }
                                onEditSave={handleTableSave}
                                onFilterUpdate={onFilterUpdate}
                                campaignDetailsFilter={campaignDetailFilters}
                                storeFiltersInBrowserStorage={
                                  storeFiltersInBrowserStorage
                                }
                                adTypes={
                                  adTypeDropDownValue
                                    ? [adTypeDropDownValue]
                                    : []
                                }
                                selectedSalesChannel={
                                  currentFlywheelSalesChannel
                                }
                                selectedAdType={adTypeDropDownValue as AdType}
                                merchantCountry={selectedMerchantCountryCode}
                                merchantType={selectedMerchantType}
                                startDate={selectedDateRange.initialStartDate}
                                endDate={selectedDateRange.initialEndDate}
                                selectedMerchantCountries={
                                  selectedMerchantCountries
                                }
                                allMerchantCountries={
                                  merchantCountriesForSelectedSalesChannel
                                }
                                merchantCountryNameForCSVDownload={
                                  merchantCountryNameForCSVDownload
                                }
                                aiEnabled={
                                  !isAiDisabledForOneOftheSelectedMerchant
                                }
                                merchantsWithAutomationEnabled={
                                  selectedMerchantsWithAutomationEnabled ?? []
                                }
                                selectedSalesChannelId={selectedSalesChannelId}
                                columnManagerOptions={columnManagerOptions}
                                selectedColumns={selectedColumns}
                                onColumnSelectionChange={
                                  onColumnSelectionChange
                                }
                                switchLevelController={switchLevelController}
                                groupNames={getAllGroupsInfoForSelectedMerchantCountry()}
                                campaignTypeQuickFilter={
                                  campaignTypeQuickFilterAdGroupTab
                                }
                                onQuickFilterClick={
                                  onQuickFilterClickAdGroupTab
                                }
                                quickFilterData={quickFilterCountsAdGroupTab}
                                setQuickFilterData={onQuickFilterUpdateAdGroup}
                                dataFetcherWithoutQuickFilter={
                                  ADGROUP_DATA_FETCHER_WITHOUT_QUICK_FILTER
                                }
                                cogsDetailsDataFetcher={cogsDetailsDataFetcher}
                                isProductConnectedForSelectedMerchant={
                                  isProductConnectedForSelectedMerchant
                                }
                              />
                            }
                          />
                        ))}
                      {adLevelDropDownSelectedValues === AdLevel.ProductAds &&
                        [
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsProductAds,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsProductAds,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayProductAds,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierProductAds,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierAdGroupsDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartBrandAmplifierCampaignAdGroupDetails,
                        ].map((path, index) => (
                          <Route
                            path={path}
                            element={
                              <TableProductAds
                                isSaveChangesLoading={isSaveChangesLoading}
                                key={path}
                                tableMode={tableMode}
                                changeTableMode={changeTableMode}
                                apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                                adLevelDropDownSelectedValues={
                                  adLevelDropDownSelectedValues
                                }
                                aoApiClient={aoApiClient}
                                exportButtonState={exportButtonState}
                                setExportButtonState={setExportButtonState}
                                selectedSalesChannelName={
                                  selectedSalesChannelName
                                }
                                request={() => AD_ITEM_REQUEST}
                                toggleTableMode={toggleTableMode}
                                dataFetcher={AD_ITEM_DATA_FETCHER}
                                activeSliderIndex={
                                  tableMode === DualModes.View ? 0 : 1
                                }
                                onEditSave={handleTableSave}
                                onFilterUpdate={onFilterUpdate}
                                adGroupDetailsFilter={adGroupDetailFilters}
                                storeFiltersInBrowserStorage={
                                  storeFiltersInBrowserStorage
                                }
                                adTypes={
                                  adTypeDropDownValue
                                    ? [adTypeDropDownValue]
                                    : []
                                }
                                selectedSalesChannel={
                                  currentFlywheelSalesChannel
                                }
                                selectedAdType={adTypeDropDownValue as AdType}
                                merchantCountry={selectedMerchantCountryCode}
                                startDate={selectedDateRange.initialStartDate}
                                endDate={selectedDateRange.initialEndDate}
                                selectedMerchantCountries={
                                  selectedMerchantCountries
                                }
                                merchantType={selectedMerchantType}
                                productConnectionStatus={
                                  productConnectionStatus
                                }
                                columnManagerOptions={getColumnManagerOptions(
                                  tabCheckboxMetadata || {}
                                )}
                                selectedColumns={selectedColumns}
                                onColumnSelectionChange={
                                  onColumnSelectionChange
                                }
                                switchLevelController={switchLevelController}
                                aiEnabled={
                                  !isAiDisabledForOneOftheSelectedMerchant
                                }
                                merchantsWithAutomationEnabled={
                                  selectedMerchantsWithAutomationEnabled ?? []
                                }
                              />
                            }
                          />
                        ))}
                      {(adLevelDropDownSelectedValues ===
                        AdLevel.KeywordTargets ||
                        adLevelDropDownSelectedValues ===
                          AdLevel.ProductTargets ||
                        adLevelDropDownSelectedValues === AdLevel.Targets) &&
                        [
                          // ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsTargets,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierKeywordTargets,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsKeywordTargets,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayTargets,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsProductsTarget,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsProductsTarget,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredDisplayAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierKeywordTargets,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierAdGroupsDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartBrandAmplifierCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredProductsCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredProductsCampaignDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsCampaignAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosKeywordTargets,
                          'amazon/sponsored-products/keyword-targets/*',
                          'walmart/sponsored-products/keyword-targets/*',
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosCampaignAdGroupDetails,
                        ].map((path, index) => {
                          return (
                            <Route
                              path={path}
                              element={
                                <TableTargets
                                  isSaveChangesLoading={isSaveChangesLoading}
                                  key={path}
                                  tableMode={tableMode}
                                  apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                                  adLevelDropDownSelectedValues={
                                    adLevelDropDownSelectedValues
                                  }
                                  changeTableMode={changeTableMode}
                                  dataFetcher={TARGET_DATA_FETCHER}
                                  request={TARGET_REQUEST}
                                  aoApiClient={aoApiClient}
                                  selectedSalesChannelName={
                                    selectedSalesChannelName
                                  }
                                  exportButtonState={exportButtonState}
                                  setExportButtonState={setExportButtonState}
                                  toggleTableMode={toggleTableMode}
                                  activeSliderIndex={
                                    tableMode === DualModes.View ? 0 : 1
                                  }
                                  onFilterUpdate={onFilterUpdate}
                                  onEditSave={handleTableSave}
                                  detailsPageFiltersForTargets={
                                    detailsPageFiltersForTargets
                                  }
                                  storeFiltersInBrowserStorage={
                                    storeFiltersInBrowserStorage
                                  }
                                  adTypes={
                                    adTypeDropDownValue
                                      ? [adTypeDropDownValue]
                                      : []
                                  }
                                  selectedSalesChannel={
                                    currentFlywheelSalesChannel
                                  }
                                  selectedAdLevel={
                                    adLevelDropDownSelectedValues as AdLevel
                                  }
                                  selectedAdType={adTypeDropDownValue as AdType}
                                  merchantCountry={selectedMerchantCountryCode}
                                  merchantType={selectedMerchantType}
                                  startDate={selectedDateRange.initialStartDate}
                                  endDate={selectedDateRange.initialEndDate}
                                  selectedMerchantCountries={
                                    selectedMerchantCountries
                                  }
                                  columnManagerOptions={getColumnManagerOptions(
                                    tabCheckboxMetadata || {}
                                  )}
                                  selectedColumns={selectedColumns}
                                  onColumnSelectionChange={
                                    onColumnSelectionChange
                                  }
                                  switchLevelController={switchLevelController}
                                  merchantsWithAutomationEnabled={
                                    selectedMerchantsWithAutomationEnabled ?? []
                                  }
                                />
                              }
                            />
                          );
                        })}
                      {adLevelDropDownSelectedValues === AdLevel.Profiles &&
                        [
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierProfiles,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSearchBrandAmplifierAdGroupsDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartBrandAmplifierCampaignAdGroupDetails,
                        ].map((path, index) => (
                          <Route
                            path={path}
                            element={
                              <TableProfiles
                                key={path}
                                tableMode={tableMode}
                                onEditSave={handleTableSave}
                                changeTableMode={changeTableMode}
                                toggleTableMode={toggleTableMode}
                                apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                                dataFetcher={PROFILES_DATA_FETCHER}
                                request={() => PROFILES_REQUEST}
                                aoApiClient={aoApiClient}
                                famApiClient={famApiClient}
                                selectedSalesChannelName={
                                  selectedSalesChannelName
                                }
                                exportButtonState={exportButtonState}
                                setExportButtonState={setExportButtonState}
                                adLevelDropDownSelectedValues={
                                  adLevelDropDownSelectedValues
                                }
                                selectedSalesChannel={
                                  currentFlywheelSalesChannel
                                }
                                selectedAdType={adTypeDropDownValue as AdType}
                                activeSliderIndex={
                                  tableMode === DualModes.View ? 0 : 1
                                }
                                onFilterUpdate={onFilterUpdate}
                                storeFiltersInBrowserStorage={
                                  storeFiltersInBrowserStorage
                                }
                                adTypes={
                                  adTypeDropDownValue
                                    ? [adTypeDropDownValue]
                                    : []
                                }
                                merchantType={selectedMerchantType}
                                portfolioNames={getPortfoliosForSelectedMerchantCountry()}
                                merchantCountry={selectedMerchantCountryCode}
                                startDate={selectedDateRange.initialStartDate}
                                endDate={selectedDateRange.initialEndDate}
                                selectedMerchantCountries={
                                  selectedMerchantCountries
                                }
                                allMerchants={
                                  merchantCountriesForSelectedSalesChannel
                                }
                                aiEnabled={
                                  !isAiDisabledForOneOftheSelectedMerchant
                                }
                                merchantsWithAutomationEnabled={
                                  selectedMerchantsWithAutomationEnabled ?? []
                                }
                                columnManagerOptions={getColumnManagerOptions(
                                  tabCheckboxMetadata || {}
                                )}
                                selectedColumns={selectedColumns}
                                onColumnSelectionChange={
                                  onColumnSelectionChange
                                }
                                switchLevelController={switchLevelController}
                              />
                            }
                          />
                        ))}
                      {adLevelDropDownSelectedValues === AdLevel.SbAds &&
                        [
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsSbAds,
                          ADS_MANAGER_EMBEDDED_ROUTES.AmazonSponsoredBrandsCampaignDetails,
                        ].map((path, index) => (
                          <Route
                            path={path}
                            element={
                              <TableSbAds
                                key={path}
                                apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                                tableMode={tableMode}
                                dataFetcher={SB_ADS_DATA_FETCHER}
                                toggleTableMode={toggleTableMode}
                                onEditSave={handleTableSave}
                                request={() => SB_ADS_REQUEST}
                                aoApiClient={aoApiClient}
                                famApiClient={famApiClient}
                                selectedSalesChannelName={
                                  selectedSalesChannelName
                                }
                                exportButtonState={exportButtonState}
                                setExportButtonState={setExportButtonState}
                                adLevelDropDownSelectedValues={
                                  adLevelDropDownSelectedValues
                                }
                                selectedSalesChannel={
                                  currentFlywheelSalesChannel
                                }
                                selectedAdType={adTypeDropDownValue as AdType}
                                activeSliderIndex={
                                  tableMode === DualModes.View ? 0 : 1
                                }
                                onFilterUpdate={onFilterUpdate}
                                storeFiltersInBrowserStorage={
                                  storeFiltersInBrowserStorage
                                }
                                adTypes={
                                  adTypeDropDownValue
                                    ? [adTypeDropDownValue]
                                    : []
                                }
                                merchantType={selectedMerchantType}
                                portfolioNames={getPortfoliosForSelectedMerchantCountry()}
                                merchantCountry={selectedMerchantCountryCode}
                                startDate={selectedDateRange.initialStartDate}
                                endDate={selectedDateRange.initialEndDate}
                                selectedMerchantCountries={
                                  selectedMerchantCountries
                                }
                                allMerchants={
                                  merchantCountriesForSelectedSalesChannel
                                }
                                merchantCountryNameForCSVDownload={
                                  merchantCountryNameForCSVDownload
                                }
                                aiEnabled={
                                  !isAiDisabledForOneOftheSelectedMerchant
                                }
                                merchantsWithAutomationEnabled={
                                  selectedMerchantsWithAutomationEnabled ?? []
                                }
                                selectedSalesChannelId={selectedSalesChannelId}
                                columnManagerOptions={columnManagerOptions}
                                selectedColumns={selectedColumns}
                                onColumnSelectionChange={
                                  onColumnSelectionChange
                                }
                                switchLevelController={switchLevelController}
                                campaignDetailFilters={campaignDetailFilters}
                              />
                            }
                          />
                        ))}
                      {adLevelDropDownSelectedValues === AdLevel.SvAds &&
                        [
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosSVAds,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosAdGroupDetails,
                          ADS_MANAGER_EMBEDDED_ROUTES.WalmartSponsoredVideosCampaignAdGroupDetails,
                        ].map((path) => (
                          <Route
                            path={path}
                            element={
                              <TableSVAds
                                activeSliderIndex={
                                  tableMode === DualModes.View ? 0 : 1
                                }
                                selectedSalesChannel={
                                  currentFlywheelSalesChannel
                                }
                                switchLevelController={switchLevelController}
                                onEditSave={handleTableSave}
                                isSaveChangesLoading={isSaveChangesLoading}
                                tableMode={tableMode}
                                toggleTableMode={toggleTableMode}
                                apiColumnsWithAdLevel={apiColumnsWithAdLevel}
                                adType={adTypeDropDownValue as AdType}
                                selectedAdType={adTypeDropDownValue as AdType}
                                aoApiClient={aoApiClient}
                                merchantCountry={selectedMerchantCountryCode}
                                salesChannel={selectedFlywheelChannel}
                                selectedMerchantCountries={
                                  selectedMerchantCountries
                                }
                                startDate={selectedDateRange.initialStartDate}
                                endDate={selectedDateRange.initialEndDate}
                                exportButtonState={exportButtonState}
                                setExportButtonState={setExportButtonState}
                                request={() => AD_ITEM_REQUEST}
                                selectedSalesChannelName={
                                  selectedSalesChannelName
                                }
                                adLevelDropDownSelectedValues={
                                  adLevelDropDownSelectedValues
                                }
                                campaignDetailFilters={campaignDetailFilters}
                                columnManagerOptions={columnManagerOptions}
                                selectedColumns={selectedColumns}
                                onColumnSelectionChange={
                                  onColumnSelectionChange
                                }
                                onFilterUpdate={onFilterUpdate}
                                storeFiltersInBrowserStorage={
                                  storeFiltersInBrowserStorage
                                }
                                dataFetcher={AD_ITEM_DATA_FETCHER}
                                aiEnabled={isAiEnabled}
                                merchantsWithAutomationEnabled={
                                  selectedMerchantsWithAutomationEnabled ?? []
                                }
                                filtersFromBrowserStorage={filtersFromStore}
                                merchantType={selectedMerchantType}
                                productConnectionStatus={
                                  productConnectionStatus
                                }
                                currencyCode={
                                  getCurrencyCodeFromMerchantCountryCode(
                                    selectedMerchantCountryCode
                                  ) as CurrencyCode
                                }
                              />
                            }
                          />
                        ))}
                    </Routes>
                  )}
                </div>
                <div className="z-20">
                  {viewTrendsContext?.isViewTrendsOpen && (
                    <ViewTrends
                      selectedDateRange={selectedDateRange}
                      aoApiClient={aoApiClient}
                      salesChannelId={selectedSalesChannelId}
                    />
                  )}
                  {viewTrendsContext?.isSKUSlideoverOpen && (
                    <ViewSkuAndKeywordSlideOutV2
                      isOpen={viewTrendsContext?.isSKUSlideoverOpen}
                      onClose={() => {
                        viewTrendsContext?.toggleSkuSlideover &&
                          viewTrendsContext.toggleSkuSlideover();
                      }}
                      adGroupId={viewTrendsContext?.entityId ?? ''}
                      suggestedAdgroup={{
                        id: viewTrendsContext?.entityId ?? '',
                        campaign: {
                          campaignType:
                            viewTrendsContext?.targetingType as CampaignTargetingType,
                          id: viewTrendsContext?.campaignId ?? '',
                          name: viewTrendsContext?.campaignName ?? '',
                          targetingType: String(
                            viewTrendsContext?.targetingType
                          ) as TargetingType,
                        },
                        name: viewTrendsContext?.entityName,
                        targetSegments: viewTrendsContext.targetSegments,
                        totalSkus: 0,
                      }}
                      merchantCountry={{
                        merchantCountryId:
                          viewTrendsContext?.merchantCountryId ?? '',
                        merchantName: viewTrendsContext?.merchantName ?? '',
                        merchantType:
                          viewTrendsContext?.merchantType as MerchantType,
                        country: selectedMerchantCountryCode,
                        salesChannelId: selectedSalesChannelId,
                        extMerchantId:
                          selectedMerchantCountryInfo?.extMerchantId,
                      }}
                      textLinkContent={intl.formatMessage({
                        id: I18nKey.SKU_CATALOG_RELATED_FLYOVER_FOOTER_PRIMARY_BUTTON_TEXT,
                      })}
                      adTypeDropDownValue={adTypeDropDownValue}
                      selectedSalesChannelName={selectedSalesChannelName}
                      onSKUTrendsLinkClick={onViewTrendsSlideoverClick}
                    />
                  )}
                </div>
              </>
            )}
          </Layout>
        )}
      </>
    </CreateChatGPTKeywordsProvider>
  );
};
AdsManager.displayName = 'AdsManager';

export default AdsManager;
